import React from 'react';
import { useEffect } from 'react';
import { loader } from './script';

function giggles() {
    useEffect(() => {
        const sectionElement = document.getElementById('blog-content');
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    })
    return (
        <main className="overflow-hidden" onLoad={loader}>
            <section id="blog-content">
                <div className="container">
                    <div className="row text-start">
                        <div className="col-md-9 m-auto py-5 my-3 px-4 px-md-0">
                            <h1 className='blog-main-heading display-6 fw-bold'>From Giggles to Squeals - MojMela's Kiddie Rides for Young Explorers</h1>
                            <p className='blog-main-date'>Wednesday November 01<sup>st</sup> 2023</p>
                            <img src="../assets/image/giggles kids rides man cover.webp" alt="Mojmela Park" className='w-100 mb-4 rounded-3' />
                            <p className='blog-main-para'>Amusement parks are the places that help you make lasting memories. The kids are the most excited ones to visit a park, and they always remember the time spent there. Moreover, the rides, the fun, the chuckles, and the smiles affect their memory. MojMela, one of the best amusement parks in Islamabad, serves these little souls with incredible rides. With the exhilarating rides specifically designed for kids, taking safety into consideration, kids can enjoy themselves fully. In this article, let's explore what attractions MojMela provides kids and how it can make their visit to the amusement park memorable.</p>
                            <h2 className='blog-sub-heading'>1. Trampoline</h2>
                            <p className="blog-main-para">On the <a href="https://en.wikipedia.org/wiki/Trampoline">Trampoline</a>, mini warriors can fight with gravity. MojMela has packed the Trampoline with action and adventure, where fun knows no boundaries. Very few amusement parks in Islamabad have trampolines in their ride list specifically designed for children. With the bounces, flips, and laughter, the kids also dive into the pits of exhilarating excitement. Moreover, safety measures are also considered so the children can have maximum fun without worry. </p>
                            <img src="../assets/image/giggles trampoline.webp" alt="Amusement Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h3 className="blog-sub-heading">2. Merry Go Round</h3>
                            <p className="blog-main-para">It's impossible for amusement parks in Islamabad not to have Merry Go Round on their list. <a href="https://www.merriam-webster.com/dictionary/merry-go-round">Merry GO Round</a> is an amusement ride with a circulating platform with horses. Children love it, and their wide smiles make the whole family excited. The theme music plays during the ride, letting the little souls sink into the magical amusement world. Let the little souls grab on beautiful horses and saddle up for floating circles of joy. This gentle ride of MojMela is also a perfect source of giggles and fun for little guests.</p>
                            <img src="../assets/image/giggles merry stand.webp" alt="Amusement Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h3 className="blog-sub-heading">3. Jump In Star</h3>
                            <p className="blog-main-para">Jump In Star is one of the most popular theme park rides. It is a freefall ride that takes the little ones to the top of the tower and then falls. Kids enjoy this ride the most with the never-ending laughter, giggles, and screams. Moreover, when they bounce down, their faces of surprise and delight tell a story of adventure. Adults can also accompany the kids if the kid is afraid to ride or can't go alone. MojMela's <a href="https://www.zamperla.com/products/jumpin-star/">Jump in Star</a> will tickle the children's stomachs with a hopping and bouncing ride.</p>
                            <img src="../assets/image/giggles jumpin star.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h3 className="blog-sub-heading">4. Mini Jet</h3>
                            <p className="blog-main-para">MojMela sets the standards high as compared to other amusement parks in Islamabad. By allowing kids to glide across skies with its Mini Jet ride, MojMela brings delight to all its visitors. Minijet is an adventurous ride that will take you to the skies in rotating jets. The kids can control the height of their jet. Besides, the Kids also rotate up and down in the sky with brighter smiles.</p>
                            <img src="../assets/image/giggles mini tea.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h4 className='blog-sub-heading'>5. Mini Teacup</h4>
                            <p className='blog-main-para'>MojMela has something for kids and adults alike. The whirling and swirling Mini Teacup rides cast a lasting effect on child memory. Not only whirling, but Mini Teacup will make you spin. You can spin the Mini Teacup by yourself at your speed and direction. This ride will make the kids giddy.</p>
                            <img src="../assets/image/giggles mini teacup.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h2 className="blog-sub-heading">6. Speedway</h2>
                            <p className="blog-main-para">With high-speed two-seater cars on an oval shape track, kids can have the fastest riding experience. Take the furious rides on Hotrod through the windy track and exciting drive. It's a nostalgic ride that will have you smiling all the way around. MojMela, the best amusement park in Islamabad, has all the safety measures for kids and adults to enjoy free rides. Kids need to hold their grip tightly and enjoy their ride.</p>
                            <img src="../assets/image/giggles speed way.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h2 className="blog-sub-heading">7. Elephant Ride</h2>
                            <p className="blog-main-para">Elephant Ride provides nostalgic amusement park vibes and a smooth ride in the air. The Elephant Ride is one of the most liked rides in the history of amusement parks in Islamabad, and the same goes for MojMela. Moreover, seeing the kids smiling and chuckling on a high Elephant ride is cheery. Its mechanism also supports self-operation, meaning kids can control the elephant's height while rotating in a circle.</p>
                            <img src="../assets/image/giggles elephant.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h2 className="blog-sub-heading">Read More From Us:</h2>
                            <p className="blog-main-para"><a href="https://mojmela.com/blog/rides-at-mojmela">Boost your mood with: 6 Must Try Rides At Mojmela</a></p>
                            <h2 className="blog-sub-heading">Final Verdict</h2>
                            <p className="blog-main-para">Whether kids or adults, MojMela, being the leader of amusement parks in Islamabad, has something for everyone. Kids seeking adventure can choose Jump In Star, Trampoline, and Speedway. At the same time, those who want excitement and fun can opt for Elephant Ride, Mini Jet, and Mini Teacup. Besides, once you enter MojMela, you will indulge in its mesmerizing rides and attractions. Come, visit, and make memories that will last forever.</p>
                        </div>
                    </div>
                </div>
            </section>
        </main >
    )
}

export default giggles
