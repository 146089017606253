import React from 'react'
import { navhover, navhoverout, opensidebar, closesidebar } from './script';
import { Link } from 'react-router-dom';

export default function header() {
    function pageOpen() {
        document.querySelector(".sidebar-close-col").click();
    }
    return (
        <header className="w-100 overflow-hidden">
            <section id="head">
                <div className="container">
                    <div className="row head-row">
                        <div className="col-6 d-flex align-items-center head-left-side">
                            <div className="mojmela-location">
                                <Link to="https://www.google.com/maps/dir/your+location/Mojmela+Park+Islamabad" target="_blank" rel="noreferrer noopener"
                                    className="text-decoration-none"><i className="fa-solid fa-location-dot pe-1"></i>
                                    Getting Here</Link>
                            </div>
                        </div>
                        <div className="col-6 head-right-side">
                            <ul id="social-links" className="d-flex list-unstyled justify-content-end m-0">
                                <li><Link to="https://www.facebook.com/mojmelapark/" aria-label="Facebook" target="_blank" rel="noreferrer noopener"
                                    className="text-decoration-none social-link"><i className="fa-brands fa-facebook"></i></Link>
                                </li>
                                <li><Link to="https://www.tiktok.com/@mojmela?_t=8f9tJlI38Ux&_r=1" aria-label="Tiktok" target="_blank" rel="noreferrer noopener"
                                    className="text-decoration-none social-link"><i className="fa-brands fa-tiktok"></i></Link>
                                </li>
                                <li><Link to="https://www.instagram.com/moj.mela/" aria-label="Instagram" target="_blank" rel="noreferrer noopener"
                                    className="text-decoration-none social-link"><i className="fa-brands fa-instagram"></i></Link>
                                </li>
                                <li><Link to="https://www.linkedin.com/company/mojmela-amusement-park/" aria-label="Linkedin" target="_blank" rel="noreferrer noopener"
                                    className="text-decoration-none social-link"><i className="fa-brands fa-linkedin"></i></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <nav id="navbar">
                <div className="container">
                    <div className="row py-1 py-lg-2 d-flex align-items-center">
                        <div className="col-3 col-lg-2 logo-col">
                            <figure className="m-0">
                                <Link to="/" className='d-flex'><img src="/assets/image/Logo.webp" alt="Mojmela-park" className="navbar-logo" /></Link>
                            </figure>
                        </div>
                        <div className="col-lg-8 p-0 d-none d-lg-block">
                            <ul id="nav-links" className="d-flex justify-content-center list-unstyled m-0">
                                <li className="nav-item"><Link to="/" className="nav-link text-white position-relative me-5">Home</Link>
                                </li>
                                <li className="nav-item"><Link to="/ride" className="nav-link text-white position-relative me-5">Our
                                    Rides</Link></li>
                                <li className="nav-item"><Link to="/blog" className="nav-link text-white position-relative me-5">Blog</Link>
                                </li>
                                <li className="nav-item"><Link to="/about" className="nav-link text-white position-relative me-5">About
                                    us</Link></li>
                                <li className="nav-item"><Link to="/deal" className="nav-link text-white position-relative me-5">Deals</Link></li>
                            </ul>
                        </div>
                        <div className="col-9 col-lg-2 d-flex justify-content-end">
                            <div className="nav-ticket d-none d-lg-block">
                                <Link to="/ride" className="text-decoration-none fs-6" onMouseEnter={navhover} onMouseOut={navhoverout}><span className="ticket-icon px-2 py-1 py-xxl-2"><svg
                                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 119.09 208.37">
                                    <g id="Layer_2" data-name="Layer 2">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                            <path
                                                d="M.23.45.55.13c7.33.07,14.66.15,22,.19,2.3,0,4.6-.07,6.89-.11.67,18.41,14.8,29.17,28,29.89a29.19,29.19,0,0,0,20-6c7.8-6,11.66-14,12-23.89,7.5,0,14.77,0,22,0,2.3,0,4.61-.14,6.91-.22l.32.32c0,.74-.1,1.49-.1,2.23q-.12,101.28-.2,202.57a12.87,12.87,0,0,0,.71,2.88l-29.16,0a3.34,3.34,0,0,1-.63-.12c-.91-19.73-16.68-29.54-29.1-29.71a29.56,29.56,0,0,0-19.27,6.31c-7.56,6-11.35,14-11.66,23.66-7.49,0-14.76,0-22,0-2.3,0-4.61.14-6.91.22L0,208.05c0-.67.1-1.34.1-2Q.22,104.82.32,3.58C.32,2.54.26,1.49.23.45ZM35.41,80.91c0-4.42,0-8.8,0-12.81L23,68.15c0,4.11,0,8.48,0,12.75Zm27.5-12.74-12.82,0c0,4.13,0,8.5-.05,12.38l12.85,0C62.9,76.45,62.9,72.12,62.91,68.17Zm27-.24L77.19,68c0,4.19,0,8.58,0,12.72l12.8,0C90,76.51,90,72.19,90,67.93Z" />
                                        </g>
                                    </g>
                                </svg></span><span className="ticket fw-bold text-black">Tickets</span></Link>
                            </div>
                            <div className="sidebar-bar d-flex align-items-center d-lg-none" onClick={opensidebar}>
                                <i className="fa-solid fa-bars fs-5"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            <div id="sidebar" className="position-fixed rounded-start-2">
                <div className="container pt-0 pb-4 position-relative">
                    <div className="row">
                        <div className="col-12 py-4 px-3 sidebar-close-col text-start" onClick={closesidebar}>
                            <i className="fa-solid fa-x sidebar-close text-white"></i>
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <figure>
                                <img src="/assets/image/Logo.webp" alt="Mojmela Park" className="w-75 position-relative sidebar-logo" />
                            </figure>
                        </div>
                        <div className="col-6 d-flex align-items-center">
                            <div className="nav-ticket">
                                <Link href="/ride" className="text-decoration-none fs-6 d-flex" onMouseEnter={navhover} onMouseOut={navhoverout}>
                                    <span className="ticket-icon px-1 py-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 119.09 208.37">
                                            <g id="Layer_2" data-name="Layer 2">
                                                <g id="Layer_1-2" data-name="Layer 1">
                                                    <path
                                                        d="M.23.45.55.13c7.33.07,14.66.15,22,.19,2.3,0,4.6-.07,6.89-.11.67,18.41,14.8,29.17,28,29.89a29.19,29.19,0,0,0,20-6c7.8-6,11.66-14,12-23.89,7.5,0,14.77,0,22,0,2.3,0,4.61-.14,6.91-.22l.32.32c0,.74-.1,1.49-.1,2.23q-.12,101.28-.2,202.57a12.87,12.87,0,0,0,.71,2.88l-29.16,0a3.34,3.34,0,0,1-.63-.12c-.91-19.73-16.68-29.54-29.1-29.71a29.56,29.56,0,0,0-19.27,6.31c-7.56,6-11.35,14-11.66,23.66-7.49,0-14.76,0-22,0-2.3,0-4.61.14-6.91.22L0,208.05c0-.67.1-1.34.1-2Q.22,104.82.32,3.58C.32,2.54.26,1.49.23.45ZM35.41,80.91c0-4.42,0-8.8,0-12.81L23,68.15c0,4.11,0,8.48,0,12.75Zm27.5-12.74-12.82,0c0,4.13,0,8.5-.05,12.38l12.85,0C62.9,76.45,62.9,72.12,62.91,68.17Zm27-.24L77.19,68c0,4.19,0,8.58,0,12.72l12.8,0C90,76.51,90,72.19,90,67.93Z" />
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                    <span className="ticket fw-bold text-black d-flex align-items-center">Tickets</span>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12">
                            <ul className="list-unstyled pt-4 px-5 text-start">
                                <li className="pb-2"><Link to="/"
                                    className="text-decoration-none text-white position-relative sidebar-link" onClick={pageOpen}>Home <i
                                        className="fa-solid fa-angle-right position-relative"></i></Link>
                                </li>
                                <li className="pb-2"><Link to="/ride"
                                    className="text-decoration-none text-white position-relative sidebar-link" onClick={pageOpen}>Our
                                    Rides <i className="fa-solid fa-angle-right position-relative"></i></Link></li>
                                <li className="pb-2"><Link to="/blog"
                                    className="text-decoration-none text-white position-relative sidebar-link" onClick={pageOpen}>Blog <i
                                        className="fa-solid fa-angle-right position-relative"></i></Link>
                                </li>
                                <li className="pb-2"><Link to="/about"
                                    className="text-decoration-none text-white position-relative sidebar-link" onClick={pageOpen}>About
                                    us <i className="fa-solid fa-angle-right position-relative"></i></Link></li>
                                <li className="pb-2"><Link to="/deal"
                                    className="text-decoration-none text-white position-relative sidebar-link" onClick={pageOpen}>Deals<i className="fa-solid fa-angle-right position-relative"></i></Link></li>

                            </ul>
                        </div>
                        <div className="col-12 position-absolute sidebar-social-col">
                            <ul className="list-unstyled d-flex justify-content-evenly m-0 py-3">
                                <li><Link to="https://www.facebook.com/mojmelapark/" target="_blank" rel="noreferrer noopener"
                                    className="text-decoration-none sidebar-social-link text-white fs-6"><i
                                        className="fa-brands fa-facebook"></i></Link>
                                </li>
                                <li><Link to="https://www.tiktok.com/@mojmela?_t=8f9tJlI38Ux&_r=1" target="_blank" rel="noreferrer noopener"
                                    className="text-decoration-none sidebar-social-link text-white fs-6"><i
                                        className="fa-brands fa-tiktok"></i></Link>
                                </li>
                                <li><Link to="https://www.instagram.com/moj.mela/" target="_blank" rel="noreferrer noopener"
                                    className="text-decoration-none sidebar-social-link text-white fs-6"><i
                                        className="fa-brands fa-instagram"></i></Link>
                                </li>
                                <li><Link to="https://www.linkedin.com/company/mojmela-amusement-park/" target="_blank" rel="noreferrer noopener"
                                    className="text-decoration-none sidebar-social-link text-white fs-6"><i
                                        className="fa-brands fa-linkedin"></i></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
