import React from 'react';
import { useEffect } from 'react';
import { loader } from './script';

function Blogpage() {
    useEffect(() => {
        const sectionElement = document.getElementById('blog-content');
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    })
    return (
        <main className="overflow-hidden" onLoad={loader}>
            <section id="blog-content">
                <div className="container">
                    <div className="row text-start">
                        <div className="col-md-9 m-auto py-5 my-3 px-4 px-md-0">
                            <h1 className='blog-main-heading display-6 fw-bold'>MojMela Unveiled: A Journey Through Islamabad's Ultimate Adventure Park</h1>
                            <p className='blog-main-date'>Wednesday August 16<sup>th</sup> 2023</p>
                            <img src="../assets/image/mojmela blog cover.webp" alt="Mojmela Park" className='w-100 mb-4 rounded-3' />
                            <p className='blog-main-para'>Islamabad is a bustling city with a busy daily life. But its serene beauty gives its residents an escape from the hectic routine. With many attractions and tourist spots, the city is the best destination for tourism and excursion. But it's amusement parks set the city apart from regular recreational sites. MojMela, one of the best amusement parks in Islamabad, is where you can share laughter with your dear ones and make lasting memories. With thrilling and adventurous rides, you can let yourself scream freely and face your inner fears. Let's Explore what attractions and adventures <a href='/' target='_blank' rel='noopener noreferrer'>MojMela</a> offer and what makes it apart from other amusement parks: </p>
                            <h2 className='blog-sub-heading'>An Ultimate Destination for Thrill Seekers:</h2>
                            <p className="blog-main-para">MojMela, surrounded by the pleasing sights of Margalla hills and Rawal Lake, is Islamabad's ultimate adventure park. MojMela provides you with a fun-filled adventure offering countless opportunities for exploration. Moreover, whether you're searching for excitement or a family-friendly activity, our theme park rides are designed to please all adventure-seekers. Also, this park's offerings are not limited to rides but host multiple events, parties, and gatherings. MojMela is the only water park in Islamabad with its thrilling water slides. </p>
                            <h3 className="blog-sub-heading">The Adventure Begins Here - Thrilling Theme Park Rides:</h3>
                            <p className="blog-main-para">
                                Step into a world of adrenaline-pumping adventure with a range of amusement park rides. Each ride at <a href='https://www.facebook.com/mojmelapark/' target='_blank' rel='noopener noreferrer'>MojMela Park</a> is meticulously designed to deliver peak exhilaration and enjoyment. From breathtaking roller coasters, ziplines, power surges, and pirate ships that defy gravity to gentle rides, MojMela prides itself as one of the leading amusement parks in Islamabad that everyone can thoroughly enjoy.<br />
                                Besides, all the rides are built by the best manufacturers, ensuring there is no space for flaws. The rides undergo various pressure tests, regular maintenance, and inspection, leaving no room for mishaps.
                            </p>
                            <h3 className="blog-sub-heading">Water Park in Islamabad - Unforgettable Water Slides</h3>
                            <p className="blog-main-para">MojMela is not only known for its amusement park rides but also its exciting water slides. As one of the top amusement parks in Islamabad, we've ensured our water slides provide the perfect cool-down with thrill and fun. Here, laughter and shrieks of delight reverberate as friends and family plunge our water slides, creating unforgettable moments.</p>
                            <h3 className="blog-sub-heading">Exhilarating Fears - The Horror House Experience</h3>
                            <p className="blog-main-para">
                                MojMela is not just a theme park in Islamabad; it's an unforgettable experience. Dare to venture into its Horror House? Experience a spine-chilling journey through a world where fear and entertainment merge. Prepare to encounter the unexpected and leave with an adrenaline rush.
                            </p>
                            <img src="../assets/image/blog sub img.webp" alt="Mojmela Park In Islamabad" className='w-100 mb-4 rounded-3' />
                            <h3 className="blog-sub-heading">Satisfy Your Cravings - Delicious Food Stalls</h3>
                            <p className="blog-main-para">At MojMela, we understand that a day filled with theme park rides can work up quite an appetite. That's why we've peppered our amusement park with diverse food stalls. Moreover, from local favorites to international cuisines, we cater to every palate, ensuring an exceptional culinary adventure for all our guests.</p>
                            <h3 className="blog-sub-heading">Event Arenas:</h3>
                            <p className="blog-main-para">Spamming across an area of 8 acres, MojMela is not limited to thrill and adventure. Also, it includes a multipurpose indoor arena, event canopy, conference room, and auditorium to host events, expos, and festivals. Whether it's a workshop or an event, MojMela has a place for everything. Moreover, from <a href='https://en.wikipedia.org/wiki/Independence_Day_(Pakistan)' target='_blank' rel='noopener noreferrer'>Independence Day</a> celebrations to kid rides and competitions, this park is a hub for capturing smiles and making memories. </p>
                            <h3 className="blog-sub-heading">Safety and Commitment:</h3>
                            <p className="blog-main-para">
                                We reaffirm our commitment to you as we continue to evolve and innovate. Your satisfaction drives us, and we strive to exceed expectations every time you visit. With an exciting blend of rides, food, and unforgettable experiences, MojMela is more than an amusement park in Pakistan - it's a joyous world waiting for you to explore. <br />
                                Furthermore, what sets MojMela apart from other amusement parks in Islamabad is our unwavering commitment to offering quality fun in a safe environment. We're not just a location but a source of joy and lasting memories.
                            </p>
                            <h2 className="blog-sub-heading">Final Verdict:</h2>
                            <p className='blog-main-para'>
                                In conclusion, a trip to an amusement park is complete with experiencing the exhilarating rides. MojMela houses an assortment of theme park rides, including adrenaline-pumping roller coasters, delightful carousels, and classic bumper cars. Besides, MojMela's journey began with a vision to be one of the leading amusement parks in Islamabad that goes beyond traditional concepts. <br />
                                Lastly, we strive to help our visitors create memorable experiences that combine fun, thrill, and great food in a unique setting. Come to MojMela, and dive into the vibrant, thrilling, and joyful world we've created.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main >
    )
}

export default Blogpage
