import React from 'react';
import { Link } from 'react-router-dom';

export default function footer() {
    return (
        <footer>
            <section id="get-in-touch">
                <div className="get-in-touch-heading py-3">
                    <h2 className="display-6 text-center fw-bold m-0 text-white">Get in touch</h2>
                </div>
                <div className="get-in-touch-main">
                    <div className="container py-3">
                        <div className="row py-3 py-lg-5 d-flex">
                            <div
                                className="col-sm-3 col-md-4 col-lg-5 d-flex align-items-center footer-logo-side d-none d-sm-flex">
                                <div className="footer-logo">
                                    <figure>
                                        <Link to="/">
                                            <img src="/assets/image/Logo.webp" alt="Mojmela-Park" className="w-50" />
                                        </Link>
                                    </figure>
                                </div>
                            </div>
                            <div className="col-12 col-sm-9 col-md-8 col-lg-7">
                                <div className="row d-flex justify-content-sm-center footer-right-side">
                                    <div className="col-5 col-sm-3 footer-right-col order-sm-0 text-start">
                                        <h3 className="fs-3 fw-bold text-white">GoTo</h3>
                                        <ul className="list-unstyled">
                                            <li><Link to="/"
                                                className="text-decoration-none text-white position-relative get-in-touch-link">Home</Link>
                                            </li>
                                            <li><Link to="/ride"
                                                className="text-decoration-none text-white position-relative get-in-touch-link">Our
                                                Rides</Link></li>
                                            <li><Link to="/park"
                                                className="text-decoration-none text-white position-relative get-in-touch-link">Park</Link>
                                            </li>
                                            <li><Link to="/blog"
                                                className="text-decoration-none text-white position-relative get-in-touch-link">Blogs</Link>
                                            </li>
                                            <li><Link to="/about"
                                                className="text-decoration-none text-white position-relative get-in-touch-link">About
                                                Us</Link></li>
                                            <li><Link to="/contat"
                                                className="text-decoration-none text-white position-relative get-in-touch-link">Contact
                                                Us</Link></li>
                                            <li><Link to="/deal"
                                                className="text-decoration-none text-white position-relative get-in-touch-link">Deals</Link></li>
                                        </ul>
                                    </div>
                                    <div className="col-6 col-sm-4 footer-right-col order-2 order-sm-1 text-start">
                                        <h3 className="fs-3 fw-bold text-white">Legal</h3>
                                        <ul className="list-unstyled">
                                            <li><Link to="#get-in-touch" className="text-decoration-none text-white">Website T&C's</Link></li>
                                            <li><Link to="#get-in-touch" className="text-decoration-none text-white">Privacy Policy</Link></li>
                                            <li><Link to="#get-in-touch" className="text-decoration-none text-white">Complaints Policy</Link>
                                            </li>
                                            <li><Link to="#get-in-touch" className="text-decoration-none text-white">Lost Property Policy</Link>
                                            </li>
                                            <li><Link to="#get-in-touch" className="text-decoration-none text-white">Filming</Link></li>
                                            <li><Link to="#get-in-touch" className="text-decoration-none text-white">Parking</Link></li>
                                        </ul>
                                    </div>
                                    <div className="col-7 col-sm-5 footer-right-col order-1 order-sm-2">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <h3 className="fs-3 fw-bold text-white text-start ps-xxl-2">Contact</h3>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="contact-detail">
                                                    <td>
                                                        <i className="fa-solid fa-phone"></i>
                                                    </td>
                                                    <td className="text-white text-start ps-xxl-2">
                                                        +92 349 6656352
                                                    </td>
                                                </tr>
                                                <tr className="contact-detail">
                                                    <td className="d-flex">
                                                        <i className="fa-solid fa-location-dot"></i>
                                                    </td>
                                                    <td className="text-white text-start ps-xxl-2">
                                                        Moj Mela Park, Plot 5, LAKE VIEW PARK, Islamabad Capital Territory 44000
                                                    </td>
                                                </tr>
                                                <tr className="contact-detail">
                                                    <td>
                                                        <i className="fa-solid fa-envelope"></i>
                                                    </td>
                                                    <td className="text-white text-start ps-xxl-2 footer-email">
                                                        mojmelapark@gmail.com
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="foot">
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <ul id="foot-links" className="d-flex list-unstyled justify-content-center justify-content-md-start m-0 py-2">
                                <li><Link to="https://www.facebook.com/mojmelapark/" aria-label="Facebook" target="_blank" rel="noreferrer noopener"
                                    className="text-decoration-none foot-link fs-4 pe-4 pe-md-5"><i
                                        className="fa-brands fa-facebook"></i></Link>
                                </li>
                                <li><Link to="https://www.tiktok.com/@mojmela?_t=8f9tJlI38Ux&_r=1" aria-label="Tiktok" target="_blank" rel="noreferrer noopener"
                                    className="text-decoration-none foot-link fs-4 pe-4 pe-md-5"><i className="fa-brands fa-tiktok"></i></Link>
                                </li>
                                <li><Link to="https://www.instagram.com/moj.mela/" aria-label="Instagram" target="_blank" rel="noreferrer noopener"
                                    className="text-decoration-none foot-link fs-4 pe-4 pe-md-5"><i
                                        className="fa-brands fa-instagram"></i></Link>
                                </li>
                                <li><Link to="https://www.linkedin.com/company/mojmela-amusement-park/" aria-label="Linkedin" target="_blank" rel="noreferrer noopener"
                                    className="text-decoration-none foot-link fs-4 pe-4 pe-md-5"><i
                                        className="fa-brands fa-linkedin"></i></Link>
                                </li>
                            </ul>
                        </div>
                        <div className='col-md-6 d-flex align-items-center justify-content-center justify-content-md-end'>
                            <p className='mb-0 mojmela-copyright-link text-black'>&copy; Mojmela 2023 All Rights Reserved | Designed By <a href='https://www.linkedin.com/company/creactiveinc/' className='text-decoration-none footer-creactive-link' target='_blank' rel="noreferrer noopener" aria-label='Creactive Linkedin Account'>Creactive</a></p>
                        </div>
                    </div>
                </div>

            </section>
        </footer>
    )
}
