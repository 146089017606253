import React from 'react';
import { useEffect } from 'react';
import { loader } from './script';

function behindTheMagic() {
    useEffect(() => {
        const sectionElement = document.getElementById('blog-content');
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    })
    return (
        <main className="overflow-hidden" onLoad={loader}>
            <section id="blog-content">
                <div className="container">
                    <div className="row text-start">
                        <div className="col-md-9 m-auto py-5 my-3 px-4 px-md-0">
                            <h1 className='blog-main-heading display-6 fw-bold'>Behind the Magic – How MojMela Creates Unforgettable Memories</h1>
                            <p className='blog-main-date'>Tuesday October 31<sup>th</sup> 2023</p>
                            <img src="../assets/image/cover behind the magic.webp" alt="Mojmela Park" className='w-100 mb-4 rounded-3' />
                            <p className='blog-main-para'>A fun and fulfilling time awaits you at MojMela Park. Tag along with your family or hang out with your friends; the best amusement park in Islamabad is a recreational place for all ages. There are numerous things you can do — whether you are a child, a young adult, or a parent — and they all hold the same promise: you are going to create unforgettable memories.<br /><br />
                                The moment you enter through the handsome grill gates of our park, you will take in the vibrant and blurred colours surrounding you. The air was abuzz with overwhelming excitement; there are just too many points of attraction at MojMela Park you have got to try. <br /><br />
                                But where to start?
                            </p>
                            <h2 className='blog-sub-heading'>Paint the Town Red at MojMela Park</h2>
                            <p className="blog-main-para">If you are here reading this blog post, consider visiting us. Ours has a few unique elements that need to be added to other amusement parks in Islamabad. After you come to our theme park, you and your company will leave with a heart full of content. <br /><br />

                                You may have come to check out our worthwhile Balloon Race or the nerve-wracking Power Surge. The open eating space with street vendors and the large arena that holds various events are also among our winning factors.
                                Let’s take a closer look at MojMela Park’s spectacular rides and how you can have a wonderful time here.
                            </p>
                            <img src="../assets/image/ballon race.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h3 className="blog-sub-heading">Test Your Nerves with Our Thrill Rides</h3>
                            <p className="blog-main-para">It’s Saturday morning, and the clock has struck the eleventh hour of the day. Those who have seen and experienced one of our scariest and fear-inducing thrill rides, Power Surge, can tell you about the absolutely exhilarating sensation they get when they ride whirls around in circles. Up in the sky, the jitters never actually go away. <br /><br />

                                If you dare to step into the short queue that forms at the Power Surge entryway, you have braved what most refuse to even think about. While the Power Surge is terrifying to the very core, it is safe and sound. Regularly inspected, you would not hear a single complaint from the maintenance department.<br /><br />
                                All the things that can go wrong are just in your head.

                            </p>
                            <h3 className="blog-sub-heading">Jump Scares Too Good To Be True</h3>
                            <p className="blog-main-para">The Horror Hub at MojMela Park cannot be underestimated for its petrifying ways. The super realistic, almost lifelike, supernatural creatures stir up the fear that you were trying so hard to repress. We suggest that, once you have experienced all the rides — from the longest family coaster to the gut-wrenching <a href='https://en.wikipedia.org/wiki/Zip_line'>Zip Line</a> — you best believe that your next stop is the Horror Hub. <br /><br />
                                The night has settled in. In late December, the cool breeze tickles your bones. Your nerves already tingle as you approach the front of Horror Hub. The bright lights of the rides stand out against the sky’s inky blackness. It's mesmerising from the outside, especially at nighttime. But beware, only the bravest come out unscathed…
                            </p>
                            <img src="../assets/image/horror house and zipline.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h3 className="blog-sub-heading">Conquer Your Fears at the Climbing Wall</h3>
                            <p className="blog-main-para">
                                Once or twice in a lifetime, there is a moment when you want to get out of your way and do something daredevil-ish. Well, now’s your time to grab the opportunity at MojMela Park. Mount to the top of our Climbing Wall and get the majestic aerial view of the best amusement park in the twin cities. <br /> <br />Capture one of the best moments of your life by reaching the top of the challenging climbing wall. It is an empowering — and definitely memorable — feeling to accomplish something a lot of people cannot put themselves through.
                            </p>
                            <img src="../assets/image/climbing wall.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h4 className='blog-sub-heading'>Learn More About MojMela</h4>
                            <p className='blog-main-para'><a href='https://mojmela.com/blog/rides-at-mojmela'>https://mojmela.com/blog/rides-at-mojmela</a></p>
                            <h3 className="blog-sub-heading">A Premium Family Time Guaranteed</h3>
                            <p className="blog-main-para">Children bring joy to our lives, and Mojela Park wants to give back some of it, too. You cannot miss out on a fantastic family theme park that encompasses the recreational needs of children, adults, and elderly folks. May it be the Play Area for toddlers, the Junior Star for a daunting (but safe) ride for kids, or the good ol’ Power Surge, which makes one nauseous just by looking at it!</p>
                            <h2 className="blog-sub-heading">Outro</h2>
                            <p className="blog-main-para">With smooth-running rides, a spacious and lovely ambience, street food vendors lining up the eating area, or the Events Area for some indoor entertainment – MojMela Park not only stands out because of its swings but also its aesthetics. Grab a camera and take photographic proofs of your memories. Renowned for being one of the best amusement parks in Islamabad, this place lives up to the hype. And God… nothing can beat the view. </p>
                        </div>
                    </div>
                </div>
            </section>
        </main >
    )
}

export default behindTheMagic
