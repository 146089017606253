import './App.css';
import Header from "./components/header";
import Footer from "./components/footer";
import Loader from './components/preloader';
import Home from "./components/homebody";
import About from "./components/aboutbody";
import Blog from "./components/blog";
import Ride from "./components/ridebody";
import Blogpage from "./components/blogPage";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Musttry from './components/mustTry';
import Culinary from './components/culinary';
import behindTheMagic from './components/behindTheMagic';
import giggles from './components/giggles';
import familyFun from './components/familyFun';
import amusementPark from './components/amusementPark';
import deal from './components/deal';

function App() {
  return (
    <div className="App">
      <Router>
        <Loader />
        <Header />
        <Routes>
          <Route path='/' Component={Home} />
          <Route path='/about' Component={About} />
          <Route path='/blog' Component={Blog} />
          <Route path='/ride' Component={Ride} />
          <Route path='/deal' Component={deal} />
          <Route path='/blog/mojmela-unveiled' Component={Blogpage} />
          <Route path='/blog/rides-at-mojmela' Component={Musttry} />
          <Route path='/blog/mojmela-food-delights' Component={Culinary} />
          <Route path='/blog/memories-at-mojmela' Component={behindTheMagic} />
          <Route path='/blog/kiddie-rides-at-mojmela' Component={giggles} />
          <Route path='/blog/major-rides-at-mojmela' Component={familyFun} />
          <Route path='/blog/best-amusement-park-in-islamabad' Component={amusementPark} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
