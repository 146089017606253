import React from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { loader } from './script';

function Ridebody() {
    const location = useLocation();
    const id = location.hash;
    useEffect(() => {
        if (id) {
            const sectionElement = document.querySelector(id);
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
        else {
            const sectionElement = document.getElementById("head");
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    }, [id])

    return (
        <main className="overflow-hidden" onLoad={loader}>
            <div className='ride-main-gradient'>
                <section id="ride-book" className="position-relative w-100 overflow-hidden">
                    <div className='main-rides py-4 position-relative' id='thrill-ride'>
                        <div className='ride-background-svg position-relative'>
                            <img src='/assets/svg/ghra.svg' alt="Mojmela-Park" className='ghora-1 position-absolute ride-background-svg-size' />
                            <img src='/assets/svg/kashti.svg' alt="Mojmela-Park" className='kashti-1 position-absolute ride-background-svg-size' />
                            <img src='/assets/svg/kashti.svg' alt="Mojmela-Park" className='kashti-2 position-absolute ride-background-svg-size' />
                            <img src='/assets/svg/roller.svg' alt="Mojmela-Park" className='roller-1 position-absolute ride-background-svg-size' />
                        </div>
                        <div className='container high-index ride-box-section rounded-4 py-5'>
                            <div className="position-relative high-index pb-5 pb-sm-0">
                                <div className='row pb-5'>
                                    <div className='col-md-11 col-lg-10 m-auto'>
                                        <h2 className='text-start ride-heading ps-2 display-6 text-center'>Thrill Rides</h2>
                                        <div className='rides-gallery kids-gallery'>
                                            <div className='row'>
                                                <div className='col-sm-6 pt-5'>
                                                    <figure className='m-0'>
                                                        <div className='ride-book-img-box overflow-hidden rounded-4'>
                                                            <img src='/assets/image/power surge ride.webp' alt='Mojmela-Park' className='w-100 ride-img last-ride-img position-relative thrill-ride-1' />
                                                        </div>
                                                        <figcaption className='ride-caption'>POWER SURGE</figcaption>
                                                    </figure>
                                                </div>
                                                <div className='col-sm-6 pt-5'>
                                                    <figure className='m-0'>
                                                        <div className='ride-book-img-box overflow-hidden rounded-4'>
                                                            <img src='/assets/image/Zipline ride.webp' alt='Mojmela-Park' className='w-100 ride-img last-ride-img position-relative thrill-ride-2' />
                                                        </div>
                                                        <figcaption className='ride-caption'>ZIP LINE</figcaption>
                                                    </figure>
                                                </div>
                                                <div className='col-sm-6 pt-5'>
                                                    <figure className='m-0'>
                                                        <div className='ride-book-img-box overflow-hidden rounded-4'>
                                                            <img src='/assets/image/super train ride.webp' alt='Mojmela-Park' className='w-100 ride-img last-ride-img position-relative thrill-ride-3' />
                                                        </div>
                                                        <figcaption className='ride-caption'>SUPER TRAIN</figcaption>
                                                    </figure>
                                                </div>
                                                <div className='col-sm-6 pt-5'>
                                                    <figure className='m-0'>
                                                        <div className='ride-book-img-box overflow-hidden rounded-4'>
                                                            <img src='/assets/image/climbing wall ride.webp' alt='Mojmela-Park' className='w-100 ride-img last-ride-img position-relative thrill-ride-4' />
                                                        </div>
                                                        <figcaption className='ride-caption'>CLIMBING WALL</figcaption>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='main-rides py-4 position-relative' id='major-ride'>
                        <div className='container high-index ride-box-section rounded-4 py-3 py-sm-4'>
                            <div className="position-relative high-index">
                                <div className='row'>
                                    <div className='col-md-11 col-lg-10 m-auto'>
                                        <h2 className='text-start ride-heading ps-2 display-6 text-center'>Major Rides</h2>
                                        <div className='rides-gallery kids-gallery'>
                                            <div className='row'>
                                                <div className='col-sm-6 pt-5'>
                                                    <figure className='m-0'>
                                                        <div className='ride-book-img-box overflow-hidden rounded-4'>
                                                            <img src='/assets/image/bumper car ride.webp' alt='Mojmela-Park' className='w-100 ride-img last-ride-img position-relative major-ride-1' />
                                                        </div>
                                                        <figcaption className='ride-caption'>BUMPER CARS</figcaption>
                                                    </figure>
                                                </div>
                                                <div className='col-sm-6 pt-5'>
                                                    <figure className='m-0'>
                                                        <div className='ride-book-img-box overflow-hidden rounded-4'>
                                                            <img src='/assets/image/pirate ship ride.webp' alt='Mojmela-Park' className='w-100 ride-img last-ride-img position-relative major-ride-2' />
                                                        </div>
                                                        <figcaption className='ride-caption'>PIRATE SHIP</figcaption>
                                                    </figure>
                                                </div>
                                                <div className='col-sm-6 pt-5'>
                                                    <figure className='m-0'>
                                                        <div className='ride-book-img-box overflow-hidden rounded-4'>
                                                            <img src='/assets/image/horror hub ride.webp' alt='Mojmela-Park' className='w-100 ride-img last-ride-img position-relative major-ride-3' />
                                                        </div>
                                                        <figcaption className='ride-caption'>HORROR HOUSE</figcaption>
                                                    </figure>
                                                </div>
                                                <div className='col-sm-6 pt-5'>
                                                    <figure className='m-0'>
                                                        <div className='ride-book-img-box overflow-hidden rounded-4'>
                                                            <img src='/assets/image/balloon race ride.webp' alt='Mojmela-Park' className='w-100 ride-img last-ride-img position-relative major-ride-4' />
                                                        </div>
                                                        <figcaption className='ride-caption'>BALLOON RACE</figcaption>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='main-rides py-5 mt-4 position-relative' id='kids-ride'>
                        <div className='ride-background-svg position-relative'>
                            <img src='/assets/svg/kashti.svg' alt="Mojmela-Park" className='kashti-3 position-absolute ride-background-svg-size' />
                            <img src='/assets/svg/roller.svg' alt="Mojmela-Park" className='roller-2 position-absolute ride-background-svg-size' />
                            <img src='/assets/svg/ghra.svg' alt="Mojmela-Park" className='ghora-2 position-absolute ride-background-svg-size' />
                            <img src='/assets/svg/kashti.svg' alt="Mojmela-Park" className='kashti-4 position-absolute ride-background-svg-size' />
                            <img src='/assets/svg/roller.svg' alt="Mojmela-Park" className='roller-3 position-absolute ride-background-svg-size' />
                        </div>
                        <div className='kids-ride-bg position-absolute'>
                            <img src="/assets/image/mojmela-ride-bg-2.webp" alt="Mojmela-Park" />
                        </div>
                        <div className='container high-index ride-box-section rounded-4 py-5'>
                            <div className="position-relative high-index">
                                <div className='row'>
                                    <div className='col-sm-10 m-auto'>
                                        <h2 className='text-start ride-heading ps-2 display-6 text-center'>Kids Rides</h2>
                                        <div className='rides-gallery kids-gallery'>
                                            <div className='row'>
                                                <div className='col-md-6 col-lg-4 pt-5'>
                                                    <figure className='m-0'>
                                                        <div className='ride-book-img-box kid-ride-img-box overflow-hidden rounded-4'>
                                                            <img src='/assets/image/jumpin star ride.webp' alt='Mojmela-Park' className='w-100 ride-img last-ride-img position-relative kids-ride-1 kids-ride' />
                                                        </div>
                                                        <figcaption className='ride-caption'>Jumpin Star</figcaption>
                                                    </figure>
                                                </div>
                                                <div className='col-md-6 col-lg-4 pt-5'>
                                                    <figure className='m-0'>
                                                        <div className='ride-book-img-box kid-ride-img-box overflow-hidden rounded-4'>
                                                            <img src='/assets/image/speed way ride.webp' alt='Mojmela-Park' className='w-100 ride-img last-ride-img position-relative kids-ride-2 kids-ride' />
                                                        </div>
                                                        <figcaption className='ride-caption'>Speed Way</figcaption>
                                                    </figure>
                                                </div>
                                                <div className='col-md-6 col-lg-4 pt-5'>
                                                    <figure className='m-0'>
                                                        <div className='ride-book-img-box kid-ride-img-box overflow-hidden rounded-4'>
                                                            <img src='/assets/image/mini jet ride.webp' alt='Mojmela-Park' className='w-100 ride-img last-ride-img position-relative kids-ride-3 kids-ride' />
                                                        </div>
                                                        <figcaption className='ride-caption'>Mini Jet</figcaption>
                                                    </figure>
                                                </div>
                                                <div className='col-md-6 col-lg-4 pt-5'>
                                                    <figure className='m-0'>
                                                        <div className='ride-book-img-box kid-ride-img-box overflow-hidden rounded-4'>
                                                            <img src='/assets/image/elephant ride.webp' alt='Mojmela-Park' className='w-100 ride-img last-ride-img position-relative kids-ride-4 kids-ride' />
                                                        </div>
                                                        <figcaption className='ride-caption'>Elephant</figcaption>
                                                    </figure>
                                                </div>
                                                <div className='col-md-6 col-lg-4 pt-5'>
                                                    <figure className='m-0'>
                                                        <div className='ride-book-img-box kid-ride-img-box overflow-hidden rounded-4'>
                                                            <img src='/assets/image/tea cup ride.webp' alt='Mojmela-Park' className='w-100 ride-img last-ride-img position-relative kids-ride-5 kids-ride' />
                                                        </div>
                                                        <figcaption className='ride-caption'>Tea Cup</figcaption>
                                                    </figure>
                                                </div>
                                                <div className='col-md-6 col-lg-4 pt-5'>
                                                    <figure className='m-0'>
                                                        <div className='ride-book-img-box kid-ride-img-box overflow-hidden rounded-4'>
                                                            <img src='/assets/image/trampoline ride.webp' alt='Mojmela-Park' className='w-100 ride-img last-ride-img position-relative kids-ride-6 kids-ride' />
                                                        </div>
                                                        <figcaption className='ride-caption'>Trampoline</figcaption>
                                                    </figure>
                                                </div>
                                                <div className='col-md-6 pt-5'>
                                                    <figure className='m-0'>
                                                        <div className='ride-book-img-box kid-ride-img-box overflow-hidden rounded-4'>
                                                            <img src='/assets/image/merry go round ride.webp' alt='Mojmela-Park' className='w-100 ride-img last-ride-img position-relative kids-ride-7' />
                                                        </div>
                                                        <figcaption className='ride-caption'>Merry Go Round</figcaption>
                                                    </figure>
                                                </div>
                                                <div className='col-md-6 pt-5'>
                                                    <figure className='m-0'>
                                                        <div className='ride-book-img-box kid-ride-img-box overflow-hidden rounded-4'>
                                                            <img src='/assets/image/indoor play area ride.webp' alt='Mojmela-Park' className='w-100 ride-img last-ride-img position-relative kids-ride-8' />
                                                        </div>
                                                        <figcaption className='ride-caption'>Indoor Play Area</figcaption>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='kids-ride-bottom-bg position-absolute'>
                            <img src="/assets/image/mojmela-ride-bg-3.webp" alt="Mojmela-Park" />
                        </div>
                    </div>
                    <div className='main-rides py-4 position-relative' id='aqua-ride'>
                        <img src="/assets/image/mojmela-ride-element-2.webp" alt="Mojmela-Park" className='position-absolute aqua-element' />
                        <div className='ride-background-svg position-relative'>
                            <img src='/assets/svg/kashti.svg' alt="Mojmela-Park" className='kashti-5 position-absolute ride-background-svg-size' />
                            <img src='/assets/svg/kashti.svg' alt="Mojmela-Park" className='kashti-6 position-absolute ride-background-svg-size' />
                            <img src='/assets/svg/ghra.svg' alt="Mojmela-Park" className='ghora-3 position-absolute ride-background-svg-size' />
                        </div>
                        <div className='container high-index ride-box-section rounded-4 py-5'>
                            <div className="position-relative high-index">
                                <div className='row'>
                                    <div className='col-sm-10 m-auto'>
                                        <h2 className='text-start ride-heading ps-2 display-6 text-center'>Aqua Rides</h2>
                                        <div className='rides-gallery kids-gallery'>
                                            <div className='row'>
                                                <div className='col-12 col-md-11 col-lg-9 m-auto pt-5 overflow-hidden'>
                                                    <figure className='m-0'>
                                                        <div className="aqua-ride-img-box rounded-4 overflow-hidden">
                                                            <img src='/assets/image/water ride.webp' alt='Mojmela-Park' className='w-100 ride-img aqua-first-img rounded-4 aqua-ride-1' />
                                                        </div>
                                                        <figcaption className='ride-caption'>Water Park</figcaption>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main >
    )
}

export default Ridebody
