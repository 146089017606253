import React from 'react'
import { loader } from './script'

function aboutbody() {
    return (
        <main className="overflow-hidden" onLoad={loader}>
            <section id="about-us" className="position-relative w-100 overflow-hidden">
                <img src="/assets/svg/amusment.svg" alt="Mojmela-Park" className="about-bg position-absolute" />
                <div className="container position-relative about-us-container my-5">
                    <div className="row">
                        <div className='col-md-9 m-auto'>
                            <div className="about-content position-relative">
                                <h1 className="display-6 about-heading py-5 text-center">About-us</h1>
                                <div className='position-relative'>
                                    <h2 className='text-start fw-bold about-para-heading pb-3'>One of Best Amusement Parks in Islamabad</h2>
                                    <p className='text-black about-para pb-5'>MojMela is the home of unrivaled fun and excitement in Islamabad. We are not just an
                                        amusement park; we are an unforgettable adventure waiting to unfold. Every visit to our
                                        amusement park promises thrilling experiences that you'll treasure forever. Since 2019 MojMela
                                        has proudly established itself as one of the most thrilling amusement parks in Islamabad. From
                                        heart-stopping roller coaster rides to tantalizing food stalls, we've got it all. With the Area of 8
                                        Acres, we provide a unique fusion of fun, adventure, and entertainment that sets us apart from
                                        other amusement parks in Pakistan.</p>
                                    <h2 className='text-start fw-bold about-para-heading pb-3'>A World of Fun and Adventure</h2>
                                    <p className='text-black about-para'>MojMela is more than just an amusement park in Islamabad. Each corner of our park is designed
                                        to immerse you in a world of fun and excitement, whether you're here for the water slides, the
                                        thrilling rides, or the captivating Horror House.
                                        Embark on an adventure like no other. MojMela invites you to make memories, live moments of
                                        joy, and share laughs with your loved ones. We are more than just a destination; we are the hub
                                        of entertainment in Islamabad.<br /> Visit MojMela today – because fun, thrill, and excitement await you here!
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default aboutbody
