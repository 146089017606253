import React, { useEffect, useState } from 'react'
import { loader } from './script'

function deal() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const getImageSource = () => {
        return screenWidth >= 768 ? '/assets/svg/deal-1.svg' : '/assets/svg/deal-1-tablet.svg';
    };
    return (
        <>
            <main className='overflow-hidden' onLoad={loader}>

                <div className="modal fade" id="displayDeal" tabindex="-1" aria-labelledby="display Deal" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <img src="/assets/image/deal-1-main.webp" alt="Amusement Parks in Islamabad" className='w-100' />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="mojmela-deals">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 text-start py-5">
                                <h1 className='fw-bold'>Exclusive Offers</h1>
                                <span data-bs-toggle="modal" data-bs-target="#displayDeal">
                                    <img src={getImageSource()} alt="Amusement park in Islamabad" className='w-100 rounded-4 my-4' />
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default deal