import React from 'react';
import { loader, readMore, reviewSplit } from '../components/script';
import { Link } from 'react-router-dom';

function homebody() {
    return (
        <>
            <main className="overflow-hidden" onLoad={loader}>
                <section id="heading" className="high-index">
                    <div className="container-fluid py-3 px-sm-5">
                        <figure className="m-0">
                            <img src="/assets/image/mojmela-heading-img.webp" alt="Mojmela-park" className="w-100 h-100" />
                        </figure>
                    </div>
                    <div className="background-svg">
                        <div className="boat position-relative">
                            <img src="/assets/svg/kashti.svg" alt="Mojmela-Park" className="position-absolute" />
                        </div>
                        <div className="horse horse-01 position-relative">
                            <img src="/assets/svg/ghra.svg" alt="Mojmela-Park" className="position-absolute" />
                        </div>
                        <div className="roller position-relative">
                            <img src="/assets/svg/roller.svg" alt="Mojmela-Park" className="position-absolute" />
                        </div>
                    </div>
                </section>
                <section id="booking" className="position-relative py-2">
                    <div className="container">
                        <div className="col-12 col-md-11 col-lg-10 high-index m-auto ticket-book rounded-4">
                            <div className="book-box py-4">
                                <h1 className="text-center text-white pb-2 fw-bold display-5">Explore Our Rides Now</h1>
                                <div className="d-flex justify-content-center">
                                    <Link to="/ride" className="btn book-btn fs-4 px-4 py-1">Explore</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div id='main-gradient position-relative'>
                    <section id="play-center" className="py-5 position-relative">
                        <div className="container high-index py-5">
                            <div className="row play-center-row">
                                <div className='col-lg-11 m-auto'>
                                    <h2 className='text-center display-6 fw-bold text-black section-heading pb-5'>Where The Fun Begins!</h2>
                                    <div className='row position-relative'>
                                        <div className='col-md-7 text-start py-4'>
                                            <div>
                                                <h3 className='play-para-heading fs-2 text-black'>MojMela: Where Smiles and Memories Await!</h3>
                                                <p className='play-para-message'>Ready to embark on a bracing journey of joy, excitement, and endless fun?
                                                    Look no further because MojMela, the divine amusement park in Islamabad, is here to offer you
                                                    an unforgettable experience like never before. With its thrilling rides, captivating attractions, and
                                                    a vibrant atmosphere, MojMela promises to be the perfect destination for adventure seekers,
                                                    families, and friends alike.</p>
                                            </div>
                                        </div>
                                        <div className='col-md-5 play-para-img-col position-relative d-flex justify-content-center align-items-center'>
                                            <div className='play-para-images position-relative'>
                                                <img src='/assets/image/play para image 1.webp' alt='Mojmela-Park' className='position-relative rounded-3' data-aos="zoom-in" data-aos-duration="1000" />
                                                <div className='img-shadow position-absolute'></div>
                                            </div>
                                        </div>
                                        <div className="pt-5">
                                            <div className="row">
                                                <div className='col-md-5 play-para-img-col play-para-image-col-2 position-relative d-flex justify-content-center align-items-center'>
                                                    <div className='play-para-images position-relative d-flex justify-content-center justify-content-md-start'>
                                                        <img src='/assets/image/play para image 2.webp' alt='Mojmela-Park' className='position-relative rounded-3' data-aos="zoom-in" data-aos-duration="1000" />
                                                        <div className='img-shadow img-shadow-2 position-absolute'></div>
                                                    </div>
                                                </div>
                                                <div className='col-md-7 text-start py-4'>
                                                    <div>
                                                        <h3 className='play-para-heading text-black'>Amusement Parks Islamabad: A Thrill-seeker's Destination</h3>
                                                        <p className='play-para-message'>As the leading amusement park in Islamabad, MojMela stands tall among the best amusement parks in Pakistan. With its state-of-the-art facilities and world-class attractions, we strive to provide an unparalleled entertainment experience that will leave you craving more. Situated in the heart of Islamabad, our park boasts a wide array of exciting rides, engaging activities, and delightful attractions that cater to visitors of all ages.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <h2 className="text-center display-6 fw-bold text-black section-heading play-heading pt-5 pb-3">Ultimate Destination for Fun</h2>
                                    <div className='row'>
                                        <div className='col-11 col-md-9 col-xxl-8 m-auto'>
                                            <p className="play-main-para">Step into a world where fun knows no boundaries, where laughter echoes, and memories are
                                                crafted every day. Our enchanting amusement park is an oasis of joy, packed with breathtaking
                                                roller coasters, captivating rides, picturesque carousels, and thrilling water slides. There's an adventure waiting at every turn! Join
                                                us in this exhilarating journey and let the magic of our amusement park sprinkle happiness in
                                                your live.</p>
                                        </div>
                                    </div>
                                    <div className='row py-4'>
                                        <div className="col-6 col-md-3 p-md-0 p-lg-1 event-col-box">
                                            <div className="play-center-box px-1 px-xl-2">
                                                <div className="img-box w-100 rounded-top-4">
                                                    <img src="/assets/image/arena.webp" alt="Mojmela-park" className="w-100 h-100" />
                                                </div>
                                                <div className="play-center-content text-center px-4 px-md-2 px-lg-4">
                                                    <h3 className="fs-6 px-0 px-xl-3 px-xxl-4 play-box-heading text-black py-2 m-0">EVENT ARENAS AND
                                                        POOLS</h3>
                                                    <p className="pb-4 text-black">MojMela is not just a place for fun; it's also a venue for creating unforgettable memories and
                                                        hideaway. Get a cool hideaway with our exclusive pools.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3 p-md-0 p-lg-1 event-col-box">
                                            <div className="play-center-box px-1 px-xl-2">
                                                <div className="img-box w-100 rounded-top-4">
                                                    <img src="/assets/image/horror house.webp" alt="Mojmela-park" className="w-100 h-100" />
                                                </div>
                                                <div className="play-center-content text-center px-4 px-md-2 px-lg-4">
                                                    <h3 className="fs-6 px-1 px-xl-4 play-box-heading text-black py-2 m-0">HORROR<br />HOUSE
                                                    </h3>
                                                    <p className="pb-4 text-black">Step inside, if you dare, where every corner holds a chilling scare. Welcome to the abyss, where
                                                        terror and thrill coexist</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3 p-md-0 p-lg-1 pt-4 pt-md-0 event-col-box">
                                            <div className="play-center-box px-1 px-xl-2">
                                                <div className="img-box w-100 rounded-top-4">
                                                    <img src="/assets/image/ballon.webp" alt="Mojmela-park" className="w-100 h-100 play-box-img" />
                                                </div>
                                                <div className="play-center-content text-center px-4 px-md-2 px-lg-4">
                                                    <h3 className="fs-6 px-1 px-xl-4 play-box-heading text-black py-2 m-0">HYPER RIDES AND
                                                        SLIDES</h3>
                                                    <p className="pb-4 text-black">From adrenaline-fueled adventures to heartwarming moments, Hyper Rides and Slides crafts
                                                        experiences that last a lifetime. </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3 p-md-0 p-lg-1 pt-4 pt-md-0 event-col-box">
                                            <div className="play-center-box px-1 px-xl-2">
                                                <div className="img-box w-100 rounded-top-4">
                                                    <img src="/assets/image/small point.webp" alt="Mojmela-park" className="w-100 h-100 small-point-img" />
                                                </div>
                                                <div className="play-center-content text-center px-4 px-md-2 px-lg-4">
                                                    <h3 className="fs-6 px-1 px-xl-4 play-box-heading text-black py-2 m-0">SMALL POINTS</h3>
                                                    <p className="pb-4 text-black">Sample mouthwatering cuisines at our diverse food stalls. Our park,
                                                        nestled amid the natural beauty of Rawal lake, also offers picnic spots for relaxing. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="background-svg">
                            <div className="boat boat-01 position-relative">
                                <img src="/assets/svg/kashti.svg" alt="Mojmela-Park" className="position-absolute" />
                            </div>
                            <div className="horse horse-02 position-relative">
                                <img src="/assets/svg/ghra.svg" alt="Mojmela-Park" className="position-absolute" />
                            </div>
                            <div className="boat boat-02 position-relative">
                                <img src="/assets/svg/kashti.svg" alt="Mojmela-Park" className="position-absolute" />
                            </div>
                            <div className="horse horse-03 position-relative">
                                <img src="/assets/svg/ghra.svg" alt="Mojmela-Park" className="position-absolute" />
                            </div>
                            <div className="horse horse-05 position-relative">
                                <img src="/assets/svg/ghra.svg" alt="Mojmela-Park" className="position-absolute" />
                            </div>
                            <div className="horse horse-07 position-relative">
                                <img src="/assets/svg/ghra.svg" alt="Mojmela-Park" className="position-absolute" />
                            </div>
                        </div>
                    </section>
                    <section id="ride" className="position-relative">
                        <div className="container py-5 high-index">
                            <h2 className="text-center fw-bold display-6 section-heading ride-heading text-black pb-4">Rides</h2>
                            <div className='row'>
                                <div className='col-md-8 m-auto'>
                                    <p className="ride-heading-para text-center">Embark on a journey of exhilaration and wonder! From dizzying spins to heart-racing drops, our
                                        theme park rides promise thrills for all ages. Dive into the magic, and let the adventure unfold!</p>
                                </div>
                            </div>
                            <div className="row py-3 py-md-4">
                                <div className="col-lg-11 m-auto">
                                    <div className="row py-2 ride-row">
                                        <div className="col-6 col-md-3 px-2 ride-col">
                                            <div className="ride-box position-relative ride-box-01 rounded-4">
                                                <Link to="/ride#kids-ride" className="text-decoration-none w-100 h-100 d-block position-relative">
                                                    <h3 className="fs-4 pt-5 ps-4 fw-bold text-white text-start">KIDS<br />RIDES</h3>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3 px-2 ride-col">
                                            <div className="ride-box position-relative ride-box-02 rounded-4">
                                                <Link to="/ride#major-ride" className="text-decoration-none w-100 h-100 d-block position-relative">
                                                    <h3 className="fs-4 pt-5 ps-4 fw-bold text-white text-start">MAJOR<br />RIDES</h3>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3 px-2 ride-col">
                                            <div className="ride-box position-relative ride-box-03 rounded-4">
                                                <Link to="/ride#aqua-ride" className="text-decoration-none w-100 h-100 d-block position-relative">
                                                    <h3 className="fs-4 pt-5 ps-4 fw-bold text-white text-start">AQUA<br />RIDES</h3>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-6 col-md-3 px-2 ride-col">
                                            <div className="ride-box ride-box-04 rounded-4 position-relative">
                                                <Link to="/ride#thrill-ride" className="text-decoration-none w-100 h-100 d-block position-relative">
                                                    <h3 className="fs-4 pt-5 ps-4 fw-bold text-white text-start">THRILL<br />RIDES</h3>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="background-svg">
                            <div className="horse horse-04 position-relative">
                                <img src="/assets/svg/ghra.svg" alt="Mojmela-Park" className="position-absolute" />
                            </div>
                        </div>
                    </section>
                    <section id="blog" className="py-5 position-relative">
                        <div className="container py-5 my-md-5 d-flex flex-column align-items-center high-index">
                            <h1 className="fw-bold display-6 text-black section-heading blog-heading pb-4">Read Our Blogs</h1>
                            <div className='row'>
                                <div className='col-md-8 m-auto'>
                                    <p className="blog-heading-para text-center text-white">Roll up and dive into a whirlwind of stories, behind-the-scenes magic, and thrilling tales from
                                        our best amusement park in Islamabad! Our blogs will tell the stories of twists and turns to make
                                        our amusement park the adventure of a lifetime. Read on and let the fun begin!</p>
                                </div>
                            </div>
                            <div className="row w-100 pt-3">
                                <div className="col-11 col-sm-12 col-md-11 col-lg-10 high-index m-auto blog-area">
                                    <div className="blog-box position-relative w-100 d-flex flex-column justify-content-center align-items-center rounded-4 overflow-hidden px-1 px-lg-5">
                                        <div className="home-blog-content-box position-relative">
                                            <h2 className="fs-2 fw-bold text-white position-relative pb-4 pb-lg-2 m-0">A Journey Through Islamabad's Ultimate Adventure Park</h2>
                                            <p className='text-white px-5 mx-5 position-relative d-none d-lg-block'>Mojmela-Park
                                                Islamabad is a bustling city with a busy daily life. But its serene beauty gives its residents an escape from the hectic routine.</p>
                                            <Link to="/blog/mojmela-unveiled" className="btn home-blog-btn text-black position-relative">Explore more</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="background-svg">
                            <div className="boat boat-03 position-relative">
                                <img src="/assets/svg/kashti.svg" alt="Mojmela-Park" className="position-absolute" />
                            </div>
                            <div className="roller roller-02 position-relative">
                                <img src="/assets/svg/roller.svg" alt="Mojmela-Park" className="position-absolute" />
                            </div>
                        </div>
                        <div className='blog-bottom-bg-img'>
                            <img src="/assets/image/mojmela-ride-bg-3.webp" alt='Mojmela-Park' className='position-absolute' />
                        </div>
                    </section>
                    <section id="events" className="py-5 position-relative">
                        <img src="/assets/image/mojmela-ride-element-2.webp" alt="Mojmela-Park" className='position-absolute event-bg-element' />
                        <div className="container pt-5 high-index">
                            <div className="row py-5 py-sm-0 py-md-4">
                                <div className="col-12 col-sm-6 event-left-box pb-sm-5 mb-sm-5">
                                    <div className="event-content px-5 px-sm-0 px-lg-5 text-start">
                                        <h1 className="fw-bold display-6 text-black section-heading event-heading pb-3">Events</h1>
                                        <p className="event-para pe-5 pe-sm-3 pe-lg-5 m-0 me-xxl-5">At MojMela, we pride ourselves on being more than just an amusement park in Islamabad. Nestled amidst the thrill of roller coasters and the laughter of children, our park stands as a vibrant hub of culture, creativity, and collaboration. Whether you're seeking local folk performances, festivals, or an expanse of expos and workshops, MojMela promises an experience like no other. <br /><br />
                                            Our versatile event canopy seamlessly transforms for intimate galas and workshops, while our equipped conference room bridges the gap between leisure and professionalism. And, with ample parking space, we ensure every experience, from arrival to farewell, is a smooth ride.</p>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 p-0">
                                    <div
                                        className="event-images position-relative d-flex justify-content-center justify-content-md-start">
                                        <img src="/assets/image/event-img-1.webp" alt="Mojmela-Park"
                                            className="rounded-3 position-relative event-image text-black" />
                                        <img src="/assets/image/event-img-2.webp" alt="Mojmela-Park"
                                            className="rounded-3 position-absolute event-image text-black" />
                                        <img src="/assets/image/event-img-3.webp" alt="Mojmela-Park"
                                            className="rounded-3 position-absolute event-image text-black" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="background-svg">
                            <div className="boat boat-04 position-relative">
                                <img src="/assets/svg/kashti.svg" alt="Mojmela-Park" className="position-absolute" />
                            </div>
                            <div className="horse horse-06 position-relative">
                                <img src="/assets/svg/ghra.svg" alt="Mojmela-Park" className="position-absolute" />
                            </div>
                        </div>
                        <div className='events-bottom-bg-img'>
                            <img src='/assets/image/mojmela-ride-bg-4.webp' alt="Mojmela-Park" className='position-absolute' />
                        </div>
                    </section>
                    <section id="reviews" className="py-5 position-relative">
                        <div className="background-svg">
                            <div className="roller-coaster position-relative">
                                <img src="/assets/svg/rollerCoaster.svg" alt="Mojmela-Park" className="position-absolute" />
                            </div>
                        </div>
                        <div className="container high-index py-5">
                            <h1 className="fw-bold display-6 text-black text-center section-heading review-heading pb-2">Customer Reviews</h1>
                            <div className="row d-flex flex-nowrap overflow-x-auto py-4 review-overflow">
                                <div className='col-lg-11 m-auto' onLoad={reviewSplit}>
                                    <div className='row d-flex flex-nowrap review-overflow'>
                                        <div className="col-12 col-sm-8 col-md-6 col-lg-4 review-col ps-1">
                                            <div className="customer-review rounded-4 position-relative">
                                                <div className="customer-profile d-flex bg-white py-2 rounded-top-4">
                                                    <div className="container">
                                                        <div className="row w-100 px-1 px-xl-4 m-auto d-flex align-items-center">
                                                            <div className="col-6 d-flex">
                                                                <h2 className="customer-name m-0 fs-4">Sana</h2>
                                                            </div>
                                                            <div className="col-6 p-0">
                                                                <ul className="list-unstyled d-flex justify-content-end m-0">
                                                                    <li className="position-relative pe-1"><img src="/assets/svg/Asset 1.svg"
                                                                        alt="Mojmela-Park" className="position-relative" /></li>
                                                                    <li className="position-relative pe-1"><img src="/assets/svg/Asset 1.svg"
                                                                        alt="Mojmela-Park" className="position-relative" /></li>
                                                                    <li className="position-relative pe-1"><img src="/assets/svg/Asset 1.svg"
                                                                        alt="Mojmela-Park" className="position-relative" /></li>
                                                                    <li className="position-relative pe-1"><img src="/assets/svg/Asset 1.svg"
                                                                        alt="Mojmela-Park" className="position-relative" /></li>
                                                                    <li className="position-relative pe-1"><img src="/assets/svg/Asset 1.svg"
                                                                        alt="Mojmela-Park" className="position-relative" /></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="customer-message">
                                                    <div className="container px-4 px-xxl-3 py-2 pb-4">
                                                        <p className="customer-para customer-para-1 text-white text-center p-2 pt-xl-4 mb-2 ">Recently visited MojMela with my family and it didn't disappoint! The Super Train was a delightful experience that both my kids and I enjoyed thoroughly. If you're seeking a mix of adventure and fun, don’t miss out on the zip line and climbing wall. Truly, Mojela knows how to mix thrill with joy!</p>
                                                        <span className='text-white read-more-review' onClick={readMore}>Read more</span>
                                                    </div>
                                                </div>
                                                <div className="arrow-box"></div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-8 col-md-6 col-lg-4 review-col">
                                            <div className="customer-review rounded-4 position-relative">
                                                <div className="customer-profile d-flex bg-white py-2 rounded-top-4">
                                                    <div className="container">
                                                        <div className="row w-100 px-1 px-xl-4 m-auto d-flex align-items-center">
                                                            <div className="col-6 d-flex">
                                                                <h2 className="customer-name m-0 fs-4">Quba</h2>
                                                            </div>
                                                            <div className="col-6 p-0">
                                                                <ul className="list-unstyled d-flex justify-content-end m-0">
                                                                    <li className="position-relative pe-1"><img src="/assets/svg/Asset 1.svg"
                                                                        alt="Mojmela-Park" className="position-relative" /></li>
                                                                    <li className="position-relative pe-1"><img src="/assets/svg/Asset 1.svg"
                                                                        alt="Mojmela-Park" className="position-relative" /></li>
                                                                    <li className="position-relative pe-1"><img src="/assets/svg/Asset 1.svg"
                                                                        alt="Mojmela-Park" className="position-relative" /></li>
                                                                    <li className="position-relative pe-1"><img src="/assets/svg/Asset 1.svg"
                                                                        alt="Mojmela-Park" className="position-relative" /></li>
                                                                    <li className="position-relative pe-1"><img src="/assets/svg/Asset 1.svg"
                                                                        alt="Mojmela-Park" className="position-relative" /></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="customer-message">
                                                    <div className="container px-4 px-xxl-3 py-2 pb-4">
                                                        <p className="customer-para customer-para-2 text-white text-center p-2 pt-xl-4 mb-2">My day at MojMela was like stepping back into my childhood, but with added adventure! The rush with the Zipline made me its fan. Not to forget the Super Train, which both my son and I couldn’t resist riding twice. The variety of rides catering to all ages makes Mojela a unique and must-visit destination for families.</p>
                                                        <span className='text-white read-more-review' onClick={readMore}>Read more</span>
                                                    </div>
                                                </div>
                                                <div className="arrow-box"></div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-8 col-md-6 col-lg-4 review-col pe-1">
                                            <div className="customer-review rounded-4 position-relative">
                                                <div className="customer-profile d-flex bg-white py-2 rounded-top-4">
                                                    <div className="container">
                                                        <div className="row w-100 px-1 px-xl-4 m-auto d-flex align-items-center">
                                                            <div className="col-6 d-flex">
                                                                <h2 className="customer-name m-0 fs-4">Ali</h2>
                                                            </div>
                                                            <div className="col-6 p-0">
                                                                <ul className="list-unstyled d-flex justify-content-end m-0">
                                                                    <li className="position-relative pe-1"><img src="/assets/svg/Asset 1.svg"
                                                                        alt="Mojmela-Park" className="position-relative" /></li>
                                                                    <li className="position-relative pe-1"><img src="/assets/svg/Asset 1.svg"
                                                                        alt="Mojmela-Park" className="position-relative" /></li>
                                                                    <li className="position-relative pe-1"><img src="/assets/svg/Asset 1.svg"
                                                                        alt="Mojmela-Park" className="position-relative" /></li>
                                                                    <li className="position-relative pe-1"><img src="/assets/svg/Asset 1.svg"
                                                                        alt="Mojmela-Park" className="position-relative" /></li>
                                                                    <li className="position-relative pe-1"><img src="/assets/svg/Asset 1.svg"
                                                                        alt="Mojmela-Park" className="position-relative" /></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="customer-message">
                                                    <div className="container px-4 px-xxl-3 py-2 pb-4">
                                                        <p className="customer-para customer-para-3 text-white text-center p-2 pt-xl-4 mb-2">If you've not been to MojMela yet, you're truly missing out. After catching my breath, I took on the climbing wall and felt like a kid again. And speaking of kids, they have a delightful selection of rides for the little ones too. Can't wait to head back and experience more!</p><span className='text-white read-more-review' onClick={readMore}>Read more</span>
                                                    </div>
                                                </div>
                                                <div className="arrow-box"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    )
}

export default homebody




