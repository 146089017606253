import React from 'react';
import { useEffect } from 'react';
import { loader } from './script';

function familyFun() {
    useEffect(() => {
        const sectionElement = document.getElementById('blog-content');
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    })
    return (
        <main className="overflow-hidden" onLoad={loader}>
            <section id="blog-content">
                <div className="container">
                    <div className="row text-start">
                        <div className="col-md-9 m-auto py-5 my-3 px-4 px-md-0">
                            <h1 className='blog-main-heading display-6 fw-bold'>Family Fun at Mojmela - Top Attractions for All Ages</h1>
                            <p className='blog-main-date'>Friday November 03<sup>rd</sup> 2023</p>
                            <img src="../assets/image/familyFun cover.webp" alt="Mojmela Park" className='w-100 mb-4 rounded-3' />
                            <p className='blog-main-para'>In the hustling and busy life of  Islamabad, families seek refuge in amusement parks to spend time and have enchanting experiences. Amusement parks in Islamabad provide them with a world of shared laughter, moments of thrill, and memories that last longer. Mojmela inevitably finds its name at the top of the list of amusement parks. This park, thoughtfully designed, caters to the excitement that families, both young and old, crave. <br />Moreover, from bumper cars to ziplines, the park's wide array of <a href='https://mojmela.com/ride'>attractions</a> ensures there's something for everyone. With the following number of attractions, families can be assured that their time spent here will be full of fun, relaxation, and thrill.</p>
                            <h2 className='blog-sub-heading'>Bumper Cars</h2>
                            <p className="blog-main-para">The arena of <a href='https://en.wikipedia.org/wiki/Bumper_cars'>Bumper Cars</a> offers more than just a ride; it's a bonding experience. Parents can enjoy themselves with their kids, making way for heaps of laughter as they bump into each other playfully. With every twist and turn, memories are forged. The whirring of the cars, the playful shouts, and the friendly collisions all combine to provide an exhilarating experience. Besides, as families step out, there's a shared joy crafted in their memories.</p>
                            <img src="../assets/image/familyFun bumber car.webp" alt="Amusement Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h3 className="blog-sub-heading">Pirate Ship</h3>
                            <p className="blog-main-para">The Pirate Ship isn't just a common ride you find in all amusement parks in Islamabad; it's an adventure trip at Mojmela. As the ship sways back and forth, families can hold onto each other, feeling the adrenaline rush for the next big swing. Parents and children alike can throw their hands in the air, embracing the gravity-defying moments. The ship offers both thrilling highs and calming lows, ensuring heartbeats sync up in shared excitement. While leaving the ship, families often find themselves swaying to its rhythm, still engulfed in the ride's magic.</p>
                            <img src="../assets/image/familyFun pirate ship.webp" alt="Amusement Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h3 className="blog-sub-heading">Balloon Race</h3>
                            <p className="blog-main-para">More than just an aerial view, the <a href='https://en.wikipedia.org/wiki/Balloon_Race_(ride)'>Balloon Race</a> provides riders with moments of harmony above Mojmela. Families can huddle together, pointing out park attractions or simply embracing the serene views of Rawal lake and lush green Margalla hills. The gentle ascends and descends mirror the soft smiles, making the experience both comforting and memorable. Children  will look to the skies, yearning for another flight amongst the clouds.</p>
                            <img src="../assets/image/familyFun ballon race.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h3 className="blog-sub-heading">Horror House</h3>
                            <p className="blog-main-para">With every corner turned, there's a shared fright or a joint scream, reminding everyone of the protective circle of family. While the frightening atmosphere increases heartbeat, the shared experience leaves goosebumps. When you exit the horror house the outside world will seem brighter with a feeling of survival. </p>
                            <img src="../assets/image/familyFun horror house.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h4 className='blog-sub-heading'>Zipline</h4>
                            <p className='blog-main-para'>The zipline at Mojmela is more than just an adrenaline rush. As family members cheer for their loved ones soaring overhead, there's shared pride and exhilaration. The bird's-eye view, the freedom of flight, and the supportive shouts from below make this ride an affirmation of trust and encouragement. Very few amusement parks in Islamabad provide an opportunity to fly through the zipline. Mojmela prides itself for proving fun for everyone.</p>
                            <img src="../assets/image/familyFun zipline.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h2 className="blog-sub-heading">Power Surge</h2>
                            <p className="blog-main-para">The dynamic spins of the <a href="https://en.wikipedia.org/wiki/Power_Surge_(ride)">Power Surge</a> offer families a chance to huddle close, sharing exhilarating moments as the world whirls around them. Every twist induces a chorus of laughter and screams, blending perfectly with the ride's rhythm. There is a dizzying joy with shared smiles that keeps riders giggling for a long time. </p>
                            <img src="../assets/image/familyfun power surge.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h2 className="blog-sub-heading">Super Train</h2>
                            <p className="blog-main-para">Being the best theme park in Islamabad, Mojmela provides the longest track of Super Train. The Super Train is not a mere train, it’s a roller coaster of chuckles and excitement. The Fast speed, twists and turns, and wangling track will make people scream out. Everyone can ride a super train alike, whether it’s a kid or its mother sitting beside him. </p>
                            <img src="../assets/image/familyFun super trian.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h2 className="blog-sub-heading">Final Verdict</h2>
                            <p className="blog-main-para">In the domain of amusement parks in Islamabad, Mojmela stands unparalleled. Its vast <a href='https://mojmela.com/ride#major-ride'>selection of attractions</a> caters for all age groups, ensuring every visitor leaves with a heart full of memories. So, the next time you're pondering over how to spend quality time with loved ones, remember that Mojmela beckons with promises of unending fun and entertainment. And always, whether you're soaring skyward in the Balloon Race or confronting your fears in the Horror House, there's a promise of safety and exhilaration combined. Truly, it's an experience not to be missed.</p>
                        </div>
                    </div>
                </div>
            </section>
        </main >
    )
}

export default familyFun;
