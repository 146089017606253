import React from 'react';
import { useEffect } from 'react';
import { loader } from './script';

function amusementPark() {
    useEffect(() => {
        const sectionElement = document.getElementById('blog-content');
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    })
    return (
        <main className="overflow-hidden" onLoad={loader}>
            <section id="blog-content">
                <div className="container">
                    <div className="row text-start">
                        <div className="col-md-9 m-auto py-5 my-3 px-4 px-md-0">
                            <h1 className='blog-main-heading display-6 fw-bold'>5 Best Amusement Parks in Islamabad</h1>
                            <p className='blog-main-date'>Wednesday November 08<sup>th</sup> 2023</p>
                            <img src="../assets/image/amusement-park main-cover.webp" alt="Mojmela Park" className='w-100 mb-4 rounded-3' />
                            <p className='blog-main-para'>Want an escape from the busy routine and have fun with your loved ones. Look no further than amusement parks in Islamabad for excitement and adventure. Islamabad is not only known for its mesmerising natural beauty, and its parks are relaxing places to bring smiles and laughter. Over the years, it has steadily grown as a hub for entertainment, boasting some of the finest amusement parks. Whether you're a thrill-seeker or a family looking for a day of fun, Islamabad parks have something for everyone. In this article, we will uncover the top five amusement parks in the city that you must visit.</p>
                            <h2 className='blog-sub-heading'>1. MojMela</h2>
                            <p className="blog-main-para">Undoubtedly, MojMela has engraved its mark as one of the best amusement parks in Islamabad since 2019. Spanning over 8 acres, <a href='https://mojmela.com/'>MojMela</a> offers a unique blend of thrilling rides, water-based attractions, and delectable food options, making it stand out amongst other amusement parks in Pakistan. The highlight of MojMela is its super train, zipline, wall climbing, and water slides . Whether it's gravity-defying roller coasters or gentler rides tailored for kids, MojMela guarantees thrills for all.<br /><br />But that's not all! As a leading amusement park in Pakistan, MojMela's water slides are the perfect antidote to the city's heat, while the Horror House promises to leave even the bravest with goosebumps. To top it off, the diverse food stalls, offering everything from mouth watering cuisines to creamy delights, ensure that visitors leave with both their hearts and stomachs full.</p>
                            <img src="../assets/image/amusement-park mojmela park.webp" alt="Amusement Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h3 className="blog-sub-heading">2. Chattar Park</h3>
                            <p className="blog-main-para">Situated among the serene greenery, Chattar Park is one of the best amusement parks in Islamabad. It's where nature and adventure meet. With beautifully crafted gardens and thrilling rides, visitors can experience the perfect blend of adventure and excitement. The rides at Chattar Park are carefully designed to cater to all ages including bumper cars and racing vehicles, making it a popular choice for families. And while there, don't forget to indulge in some local delicacies that mirror the rich culture of Pakistan.<br /><br />Located at Korang River, this park has various refreshment options. With the addition of a snake house, bird park, horse, and camel rides this park boasts new attractions. </p>
                            <img src="../assets/image/amusement-park Chattar Park.webp" alt="Amusement Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h3 className="blog-sub-heading">3. Laila Carnival Park</h3>
                            <p className="blog-main-para">If you're seeking endless fun, then <a href='https://vymaps.com/PK/Laila-Carnival-Park-529652/'>Laila Carnival Park</a> is the place to be. It is located in lakeview park surrounded by lush green mountains and Rawal Lake. Being one of the best amusement parks in Islamabad, it promises a day filled with laughter, thrills, and memories to cherish. Be it the unique wildlife area, kid’s zone, adventurous ATV racing, token rides, or food stalls, Laila Carnival Park ensures an unforgettable amusement park experience.</p>
                            <img src="../assets/image/amusement-park Laila carnival.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h3 className="blog-sub-heading">4. Fun City</h3>
                            <p className="blog-main-para">Right in the Centaurus, Fun City is more than just an amusement park; it's an experience. Being Pakistan’s first indoor family park, it is renowned for its state-of-the-art amusement park rides, unique theme, and cool architecture. It caters to both adrenaline junkies and those looking for a more relaxed day out. As you dive into the world of theme park rides, you'll realize why FunCity is one of the top amusement parks in Islamabad. With a vast play area and kiddy rides, it also provides attraction rides and games. Moreover, it also hosts events, and concerts, according to people's needs and demands.</p>
                            <img src="../assets/image/amusement-park Funcity.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h3 className='blog-sub-heading'>5. YoYo Land</h3>
                            <p className='blog-main-para'>Lastly, YoYo Land, a hub for fun and excitement for children and adults alike. With exhilarating rides to carnival games, virtual reality, and YoYo Land promises a day of pure joy. But what truly sets it apart is its commitment to safety, ensuring that while visitors have the time of their lives, they're also well taken care of. YoYo Land is one of the largest theme parks in Islamabad with an area of 40,000 square-foot. The park is equipped with different attractions and over 100 carnival games. It also hosts events and birthday parties with a capacity of 300 people.</p>
                            <img src="../assets/image/amusement-park yoyo land.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h2 className="blog-sub-heading">Conclusion</h2>
                            <p className="blog-main-para">While Islamabad has multiple attractions to its name, its amusement parks remain a significant draw. <a href="https://mojmela.com/ride">MojMela</a>, with its diverse offerings and unparalleled adventures, certainly is the best adventure park. However, each park, with its unique mix of thrill and fun, ensures that visitors have a memorable experience. So, the next time, make sure to explore these amusement parks in Islamabad and dive into a world of excitement and joy. Whether you're a local or a tourist, these parks are a testament to Islamabad's commitment to entertainment and leisure, ensuring that every visit is unforgettable.</p>
                            <img src="../assets/image/familyfun power surge.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                        </div>
                    </div>
                </div>
            </section>
        </main >
    )
}

export default amusementPark;
