import React from 'react';
import { useEffect } from 'react';
import { loader } from './script';

function Musttry() {
    useEffect(() => {
        const sectionElement = document.getElementById('blog-content');
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    })
    return (
        <main className="overflow-hidden" onLoad={loader}>
            <section id="blog-content">
                <div className="container">
                    <div className="row text-start">
                        <div className="col-md-9 m-auto py-5 my-3 px-4 px-md-0">
                            <h1 className='blog-main-heading display-6 fw-bold'>Top 6 Must-Try Rides at MojMela – Thrills and Chills Await!</h1>
                            <p className='blog-main-date'>Thursday October 19<sup>th</sup> 2023</p>
                            <img src="../assets/image/main cover-blog.webp" alt="Mojmela Park" className='w-100 mb-4 rounded-3' />
                            <p className='blog-main-para'>While there are many amusement parks in Islamabad, each shares different rides and experiences. Amusement parks differ with different sets and attractions; even with the same ride names, the experience is different. MojMela outshines the parks in Islamabad with its sheer number of rides and fantastic location. Spreading across the vast area, the park includes more than 15 rides. Moreover, the rides are distributed in categories such as kiddy, primary, and thrill rides. It also serves as the only water park in the city with its spectacular water slides. In this article, we will explore 10 must-try <a href='https://mojmela.com/ride' target='_blank'>rides at MojMela</a>. We hope that these choices will help you select your favorite rides and get the most out of your trip.  </p>
                            <h2 className='blog-sub-heading'>1. Super Train</h2>
                            <p className="blog-main-para">You will find the longest-track super train by comparing MojMela with all the amusement parks in Islamabad. With twists and turns, it fulfills your need for speed, adventure, and excitement. It provides the best experience with the safest environment and quality management. The speed changes alternatively, which gives a never-ending adrenaline rush. It’s equally adventurous and fun for kids and adults so you can ride it with the whole family. Once you ride a super train, you cannot hold back your smiles, let alone the screams and chuckles. </p>
                            <img src="../assets/image/super train-blog.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h3 className="blog-sub-heading">2. Zipline</h3>
                            <p className="blog-main-para">
                                MojMela, one of the best amusement parks in Islamabad, provides opportunities for people of all ages to seek adventure with this Zipline. Nothing can beat the excitement of swiping down while hanging above the ground and ripping through the air with weaving hands. MojMela’s Zipline is the ultimate destination for thrill seekers and explorers. This Zipline takes you 200 meters above back to land again. While swiping down, you enjoy incredible views of the serene Margalla hills, Rawal Lake, and full <a href='https://www.google.com/maps/place/LAKE+VIEW+PARK,+Islamabad,+Islamabad+Capital+Territory/@33.7163768,73.1307159,15z/data=!3m1!4b1!4m6!3m5!1s0x38dfc04b8cff92e7:0x4fc0ece5b8b9d8fb!8m2!3d33.7154975!4d73.1305349!16s%2Fg%2F11f36tgnzk?entry=ttu'>Lakeview Park</a>.
                            </p>
                            <img src="../assets/image/zipline-blog.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h3 className="blog-sub-heading">3. Climbing Wall</h3>
                            <p className="blog-main-para">Our soul is never satisfied with what we have; we strive to have more. Show your stamina and will to reach the top. If MojMela’s Super Train and Zipline can’t satisfy your cravings for fun and adventure, its Climbing Wall will cover you. Whether you are a kid or adult, beginner or climbing expert, Climbing Wall is a way to seek thrill and adrenaline pumping. Hold your Grip and conquer the exhilarating climbs with our theme park rides. </p>
                            <img src="../assets/image/climbing wall-blog.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3  blog-sub-img' />
                            <h3 className="blog-sub-heading">4. Bumper Car</h3>
                            <p className="blog-main-para">
                                Want a bump of happiness and excitement, or sharing the fun with dear ones? MojMela’s Bumper Cars have you covered. Being the best amusement park in Islamabad, MojMela has something for everyone: excitement, fun, or thrill. So, with Bumper Cars, you can whirl around, lock the target, and bump your car with friends for an ultimate adrenaline rush. For safety purposes, kids with a height of 55 inches or above can also ride Bumper cars.
                            </p>
                            <img src="../assets/image/go carts-blog.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h3 className="blog-sub-heading">5. Power Surge</h3>
                            <p className="blog-main-para">MojMela proves that amusement parks in Islamabad are not just parks but a hub for forgetting worries. <a href='https://en.wikipedia.org/wiki/Power_Surge_(ride)'>Power Surge</a> is a high-impact thrill ride of MojMela, which gives high speed and a stormy experience. With its twists and turns up in the sky, it makes you scream louder. With the heart in your neck and an adrenaline rush, you fly high in the sky while spinning and twisting. If you want to prove your inner thrill seeker or defy fear, this ride can make you shriek as you surge through the sky. Power Surge ride is not for the timid!</p>
                            <img src="../assets/image/power surge-blog.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h3 className="blog-sub-heading">6. Water Slides</h3>
                            <p className="blog-main-para">Very few amusement parks in Islamabad also serve as water parks. MojMela is the only park in Islamabad that provides stimulating Water Slides. Get an excellent adventurous escape from the scorching heat with these Water slides. You can feel the adrenaline rush as you splash through these Water Slides. These water slides are made considering both kids and adults. Whether you come with your family or friends, this ride can give you maximum fun on the way down.</p>
                            <img src="../assets/image/water park-blog.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h2 className="blog-sub-heading">Final Verdict</h2>
                            <p className="blog-main-para">
                                Although many amusement parks in Islamabad can provide fun and excitement, MojMela’s adventurous rides take it to another level. Once you visit the park, it will make you crave more. The fun, laughter, smiles, and thrills will cast a lasting effect on your soul. Hope this guide has made you familiar with our rides. Looking forward to having you at MojMela and serving you with the best of us.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main >
    )
}

export default Musttry
