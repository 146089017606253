import React from 'react'
import { moveangleright, moveangleleft, loader } from './script';
import { Link } from 'react-router-dom';

function blog() {
    return (
        <main className="overflow-hidden" onLoad={loader}>
            <section id="our-blog">
                <div className="container">
                    <div className="row px-3 px-sm-0">
                        <div className="col-12">
                            <div className="blog-title rounded-4">
                                <h1 className='m-0 display-4 section-heading'>Blogs</h1>
                            </div>
                        </div>
                        <div className="col-12 blog-boxes rounded-5">
                            <div className="row">
                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="blog-box rounded-4 overflow-hidden">
                                        <div className="blog-img-box overflow-hidden">
                                            <Link to="/blog/mojmela-unveiled"><img src="/assets/image/mojmela blog.webp" alt="Mojmela-Park" className='w-100' /></Link>
                                        </div>
                                        <div className="blog-description-box">
                                            <div className="blog-date d-flex justify-content-between py-1 mb-2">
                                                <h4 className='fs-6 m-0'>Mojmela</h4>
                                                <h4 className='fs-6 m-0'>16/08/2023</h4>
                                            </div>
                                            <div className="blog-heading-box pt-2 pb-2">
                                                <p className='text-start blogger-line mb-2'>Author: <span className="blogger-name">Shagufta Siddique</span></p>
                                                <h3 className='text-start fs-5 blog-heading'><Link to="/blog/mojmela-unveiled" className="text-decoration-none text-black blog-extra-heading">MojMela Unveiled: A Journey Through Islamabad's Ultimate Adventure Park</Link></h3>
                                            </div>
                                            <div className="blog-description">
                                                <p className='blog-para'>Islamabad is a bustling city with a busy daily life...</p>
                                            </div>
                                            <div className="blog-link d-flex pb-2">
                                                <div className="blog-learn-btn py-1 px-3 rounded-3">
                                                    <Link to="/blog/mojmela-unveiled" className='blog-link text-white text-decoration-none d-flex align-items-center' onMouseEnter={moveangleright} onMouseOut={moveangleleft}>Explore more <i className="fa-solid fa-arrow-right ps-1 position-relative"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="blog-box rounded-4 overflow-hidden">
                                        <div className="blog-img-box overflow-hidden">
                                            <Link to="/blog/rides-at-mojmela"><img src="/assets/image/main-subCover-blog.webp" alt="Mojmela-Park" className='w-100' /></Link>
                                        </div>
                                        <div className="blog-description-box">
                                            <div className="blog-date d-flex justify-content-between py-1 mb-2">
                                                <h4 className='fs-6 m-0'>Mojmela</h4>
                                                <h4 className='fs-6 m-0'>19/10/2023</h4>
                                            </div>
                                            <div className="blog-heading-box pt-2 pb-2">
                                                <p className='text-start blogger-line mb-2'>Author: <span className="blogger-name">Shagufta Siddique</span></p>
                                                <h3 className='text-start fs-5 blog-heading'><Link to="/blog/rides-at-mojmela" className="text-decoration-none text-black blog-extra-heading">Top 6 Must-Try Rides at MojMela – Thrills and Chills Await!</Link></h3>
                                            </div>
                                            <div className="blog-description">
                                                <p className='blog-para'>While there are many amusement parks in Islamabad...</p>
                                            </div>
                                            <div className="blog-link d-flex pb-2">
                                                <div className="blog-learn-btn py-1 px-3 rounded-3">
                                                    <Link to="/blog/rides-at-mojmela" className='blog-link text-white text-decoration-none d-flex align-items-center' onMouseEnter={moveangleright} onMouseOut={moveangleleft}>Explore more <i className="fa-solid fa-arrow-right ps-1 position-relative"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="blog-box rounded-4 overflow-hidden">
                                        <div className="blog-img-box overflow-hidden">
                                            <Link to="/blog/mojmela-food-delights"><img src="/assets/image/culinary-blog-subCover-img.webp" alt="Mojmela-Park" className='w-100' /></Link>
                                        </div>
                                        <div className="blog-description-box">
                                            <div className="blog-date d-flex justify-content-between py-1 mb-2">
                                                <h4 className='fs-6 m-0'>Mojmela</h4>
                                                <h4 className='fs-6 m-0'>23/10/2023</h4>
                                            </div>
                                            <div className="blog-heading-box pt-2 pb-2">
                                                <p className='text-start blogger-line mb-2'>Author: <span className="blogger-name">Afza Muazzam</span></p>
                                                <h3 className='text-start fs-5 blog-heading'><Link to="/blog/mojmela-food-delights" className="text-decoration-none text-black blog-extra-heading">A Culinary Adventure: Tasting Your Way Through MojMela’s Food Delights</Link></h3>
                                            </div>
                                            <div className="blog-description">
                                                <p className='blog-para'>A good time always involves good food, and we at...</p>
                                            </div>
                                            <div className="blog-link d-flex pb-2">
                                                <div className="blog-learn-btn py-1 px-3 rounded-3">
                                                    <Link to="/blog/mojmela-food-delights" className='blog-link text-white text-decoration-none d-flex align-items-center' onMouseEnter={moveangleright} onMouseOut={moveangleleft}>Explore more <i className="fa-solid fa-arrow-right ps-1 position-relative"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="blog-box rounded-4 overflow-hidden">
                                        <div className="blog-img-box overflow-hidden">
                                            <Link to="/blog/memories-at-mojmela"><img src="/assets/image/mobile cover magic.webp" alt="Mojmela-Park" className='w-100' /></Link>
                                        </div>
                                        <div className="blog-description-box">
                                            <div className="blog-date d-flex justify-content-between py-1 mb-2">
                                                <h4 className='fs-6 m-0'>Mojmela</h4>
                                                <h4 className='fs-6 m-0'>31/10/2023</h4>
                                            </div>
                                            <div className="blog-heading-box pt-2 pb-2">
                                                <p className='text-start blogger-line mb-2'>Author: <span className="blogger-name">Afza Muazzam</span></p>
                                                <h3 className='text-start fs-5 blog-heading'><Link to="/blog/memories-at-mojmela" className="text-decoration-none text-black blog-extra-heading">Behind the Magic – How MojMela Creates Unforgettable Memories</Link></h3>
                                            </div>
                                            <div className="blog-description">
                                                <p className='blog-para'>A fun and fulfilling time awaits you at MojMela Park...</p>
                                            </div>
                                            <div className="blog-link d-flex pb-2">
                                                <div className="blog-learn-btn py-1 px-3 rounded-3">
                                                    <Link to="/blog/memories-at-mojmela" className='blog-link text-white text-decoration-none d-flex align-items-center' onMouseEnter={moveangleright} onMouseOut={moveangleleft}>Explore more <i className="fa-solid fa-arrow-right ps-1 position-relative"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="blog-box rounded-4 overflow-hidden">
                                        <div className="blog-img-box overflow-hidden">
                                            <Link to="/blog/kiddie-rides-at-mojmela"><img src="/assets/image/giggles blog cover.webp" alt="Mojmela-Park" className='w-100' /></Link>
                                        </div>
                                        <div className="blog-description-box">
                                            <div className="blog-date d-flex justify-content-between py-1 mb-2">
                                                <h4 className='fs-6 m-0'>Mojmela</h4>
                                                <h4 className='fs-6 m-0'>01/11/2023</h4>
                                            </div>
                                            <div className="blog-heading-box pt-2 pb-2">
                                                <p className='text-start blogger-line mb-2'>Author: <span className="blogger-name">Shagufta Siddique</span></p>
                                                <h3 className='text-start fs-5 blog-heading'><Link to="/blog/kiddie-rides-at-mojmela" className="text-decoration-none text-black blog-extra-heading">From Giggles to Squeals - MojMela's Kiddie Rides for Young Explorers</Link></h3>
                                            </div>
                                            <div className="blog-description">
                                                <p className='blog-para'>Amusement parks are the places that help you make lasting memories...</p>
                                            </div>
                                            <div className="blog-link d-flex pb-2">
                                                <div className="blog-learn-btn py-1 px-3 rounded-3">
                                                    <Link to="/blog/kiddie-rides-at-mojmela" className='blog-link text-white text-decoration-none d-flex align-items-center' onMouseEnter={moveangleright} onMouseOut={moveangleleft}>Explore more <i className="fa-solid fa-arrow-right ps-1 position-relative"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="blog-box rounded-4 overflow-hidden">
                                        <div className="blog-img-box overflow-hidden">
                                            <Link to="/blog/major-rides-at-mojmela"><img src="/assets/image/familyFun mobile cover.webp" alt="Mojmela-Park" className='w-100' /></Link>
                                        </div>
                                        <div className="blog-description-box">
                                            <div className="blog-date d-flex justify-content-between py-1 mb-2">
                                                <h4 className='fs-6 m-0'>Mojmela</h4>
                                                <h4 className='fs-6 m-0'>03/11/2023</h4>
                                            </div>
                                            <div className="blog-heading-box pt-2 pb-2">
                                                <p className='text-start blogger-line mb-2'>Author: <span className="blogger-name">Shagufta Siddique</span></p>
                                                <h3 className='text-start fs-5 blog-heading'><Link to="/blog/major-rides-at-mojmela" className="text-decoration-none text-black blog-extra-heading">Family Fun at Mojmela - Top Attractions for All Ages</Link></h3>
                                            </div>
                                            <div className="blog-description">
                                                <p className='blog-para'>In the hustling and busy life of  Islamabad, families seek...</p>
                                            </div>
                                            <div className="blog-link d-flex pb-2">
                                                <div className="blog-learn-btn py-1 px-3 rounded-3">
                                                    <Link to="/blog/major-rides-at-mojmela" className='blog-link text-white text-decoration-none d-flex align-items-center' onMouseEnter={moveangleright} onMouseOut={moveangleleft}>Explore more <i className="fa-solid fa-arrow-right ps-1 position-relative"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-lg-4 mb-5">
                                    <div className="blog-box rounded-4 overflow-hidden">
                                        <div className="blog-img-box overflow-hidden">
                                            <Link to="/blog/best-amusement-park-in-islamabad"><img src="/assets/image/amusement-park mobile-cover.webp" alt="Mojmela-Park" className='w-100' /></Link>
                                        </div>
                                        <div className="blog-description-box">
                                            <div className="blog-date d-flex justify-content-between py-1 mb-2">
                                                <h4 className='fs-6 m-0'>Mojmela</h4>
                                                <h4 className='fs-6 m-0'>03/11/2023</h4>
                                            </div>
                                            <div className="blog-heading-box pt-2 pb-2">
                                                <p className='text-start blogger-line mb-2'>Author: <span className="blogger-name">Shagufta Siddique</span></p>
                                                <h3 className='text-start fs-5 blog-heading'><Link to="/blog/best-amusement-park-in-islamabad" className="text-decoration-none text-black blog-extra-heading">5 Best Amusement Parks in Islamabad</Link></h3>
                                            </div>
                                            <div className="blog-description">
                                                <p className='blog-para'>Want an escape from the busy routine and have fun with...</p>
                                            </div>
                                            <div className="blog-link d-flex pb-2">
                                                <div className="blog-learn-btn py-1 px-3 rounded-3">
                                                    <Link to="/blog/best-amusement-park-in-islamabad" className='blog-link text-white text-decoration-none d-flex align-items-center' onMouseEnter={moveangleright} onMouseOut={moveangleleft}>Explore more <i className="fa-solid fa-arrow-right ps-1 position-relative"></i></Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default blog
