import React from 'react'

function preloader() {
    return (
        <div id="pre-loader" className=" position-fixed d-flex justify-content-center align-items-center">
            <img src="/assets/svg/logomojpark.svg" alt="Mojmela-Park" className="logomojpark" />
            <img src="/assets/svg/chakrview.svg" alt="Mojmela-Park" className="chakrview position-absolute" />
        </div>
    )
}

export default preloader
