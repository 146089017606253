// pre-loader
function loader() {
    const preLoader = document.getElementById("pre-loader");
    const chakrView = preLoader.querySelector(".chakrview");
    setTimeout(() => {
        preLoader.style.background = "#ff6700";
        chakrView.style.animation = "rotate-chakr 3s infinite";
        preLoader.style.transform = "scale(1.5)";
    }, 150);
    setTimeout(() => {
        preLoader.style.visibility = "hidden";
    }, 300);
}
// navbar ticket button hover
function navhover() {
    const navTicket = document.querySelector('.nav-ticket');
    const ticket = document.querySelector('span.ticket');
    const ticketIcon = document.querySelector('span.ticket-icon');
    navTicket.addEventListener('mouseenter', () => {
        ticket.style.boxShadow = "0px 0px 14px 0px var(--head-color)";
        ticketIcon.style.boxShadow = "0px 0px 14px 0px var(--head-color)";
    })
}
function navhoverout() {
    const navTicket = document.querySelector('.nav-ticket');
    const ticket = document.querySelector('span.ticket');
    const ticketIcon = document.querySelector('span.ticket-icon');
    navTicket.addEventListener('mouseout', () => {
        ticket.style.boxShadow = "none";
        ticketIcon.style.boxShadow = "none";
    })
}
// sidebar display
function opensidebar() {
    const sidebar = document.getElementById("sidebar");
    sidebar.style.display = "block";
    setTimeout(() => {
        sidebar.style.translate = "0%";
    }, 200);
    const main = document.getElementsByTagName("body")[0];
    main.style.overflow = "hidden";
}
function closesidebar() {
    const sidebar = document.getElementById("sidebar");
    setTimeout(() => {
        sidebar.style.display = "none";
    }, 1200);
    sidebar.style.translate = "100%";
    const main = document.getElementsByTagName("body")[0];
    main.style.overflow = "auto";
}

function moveangleright(e) {
    try {
        let angleRight = e.target.querySelector("i");
        angleRight.style.left = "5px";
    } catch (err) { }
}
function moveangleleft(e) {
    try {
        let angleRight = e.target.querySelector("i");
        angleRight.style.left = "0px";
    } catch (err) { }
}
function reviewSplit(e) {
    let reviewMessage = document.querySelectorAll("p.customer-para");
    for (let i = 0; i < reviewMessage.length; i++) {
        let splitReview = reviewMessage[i].firstChild.splitText(128).previousSibling.nodeValue;
        reviewMessage[i].innerText = splitReview;
    }
}
function readMore(e) {
    const extraPara = e.target.parentNode.querySelector("p.customer-para");
    let readText = e.target.innerText;
    if (readText === "Read Less") {
        e.target.innerText = "Read more";
        setTimeout(() => {
            reviewSplit(e);
        }, 1);
    }
    else {
        if (extraPara.classList.contains("customer-para-1")) {
            extraPara.innerText = "Recently visited MojMela with my family and it didn't disappoint! The Super Train was a delightful experience that both my kids and I enjoyed thoroughly. If you're seeking a mix of adventure and fun, don’t miss out on the zip line and climbing wall. Truly, Mojela knows how to mix thrill with joy!";
        }
        else if (extraPara.classList.contains("customer-para-2")) {
            extraPara.innerText = "My day at MojMela was like stepping back into my childhood, but with added adventure! The rush with the Zipline made me its fan. Not to forget the Super Train, which both my son and I couldn’t resist riding twice. The variety of rides catering to all ages makes Mojela a unique and must-visit destination for families.";
        }
        else if (extraPara.classList.contains("customer-para-3")) {
            extraPara.innerText = "If you've not been to MojMela yet, you're truly missing out. After catching my breath, I took on the climbing wall and felt like a kid again. And speaking of kids, they have a delightful selection of rides for the little ones too. Can't wait to head back and experience more!";
        }
        e.target.innerText = "Read Less";
    }
}
export { loader, navhover, navhoverout, opensidebar, closesidebar, readMore, moveangleright, moveangleleft, reviewSplit };