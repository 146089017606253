import React from 'react';
import { useEffect } from 'react';
import { loader } from './script';

function Culinary() {
    useEffect(() => {
        const sectionElement = document.getElementById('blog-content');
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    })
    return (
        <main className="overflow-hidden" onLoad={loader}>
            <section id="blog-content">
                <div className="container">
                    <div className="row text-start">
                        <div className="col-md-9 m-auto py-5 my-3 px-4 px-md-0">
                            <h1 className='blog-main-heading display-6 fw-bold'>A Culinary Adventure: Tasting Your Way Through MojMela’s Food Delights</h1>
                            <p className='blog-main-date'>Monday October 23<sup>th</sup> 2023</p>
                            <img src="../assets/image/culinary-blog-cover-img.webp" alt="Mojmela Park" className='w-100 mb-4 rounded-3' />
                            <p className='blog-main-para'>A good time always involves good food, and we at MojMela Amusement Park are all up for it. To thoroughly enjoy every second spent at our site, one must be on a full stomach. The better the food, the more energetic you will be. We wish to tap into your fun side and let the liveliness consume you. </p>
                            <h2 className='blog-sub-heading'>All Rides and Smiles at MojMela</h2>
                            <p className="blog-main-para">Our open space allows families and friends to stroll around and enjoy the vibrant view of the best amusement park in Islamabad. From time to time, you would catch the gleeful screams from nearby <a href="https://mojmela.com/ride">MojMela thrill rides</a> like our Power Surge, or the hearty laughter from the Mini Teacup side, see people waving down from inside our Balloon Ride, or catch the muffled squeals from children’s Indoor Play Area.<br /><br />
                                The perks do not end here!<br /><br />
                                As you hop from one ride to another, the growing urge to peek at our food section becomes too hard to repress. The cool breeze brushes your hair and brings the delicious aromas of warm burritos, spicy gol gappas, juicy club sandwiches, and mouth-watering loaded fries. Do you take it or leave it?</p>
                            <img src="../assets/image/culinary-golgappa-img.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h3 className="blog-sub-heading">End Your Evening by Tucking' In</h3>
                            <p className="blog-main-para">While we offer no specific menu at MojMela, we have a variety: fast food, desi dishes, Oriental and Chinese. Craving seafood after stepping out of the Pirate Ship? We have got you covered. Need to dig into a bowl of steamy, creamy pasta? Say no more.<br /><br />
                                Our amusement park is a place to bring you absolute joy, and somewhere you can also sit and eat under the glimmering lights, the vast sky above draping you with quiet tranquillity. The food vendors surround the sitting space, enveloping you in a relaxed vibe filled with inviting aromas.<br /><br />
                                Let’s peruse the food options you have from our eateries.
                            </p>
                            <h3 className="blog-sub-heading">Make it Homely, Eat Desi</h3>
                            <p className="blog-main-para">If you are outside, you can still bring the taste of your home with you at MojMela. The food stalls that occupy the eating space provide spicy rice and meaty goodness of biryani, thick and brothy haleem, and delicious nihari and naan combination; everything that reminds you to have a warm and toasty meal at home. Even if the weather calls for pakoras, our stalls have the oil fried up. </p>
                            <h3 className="blog-sub-heading">Nothing Like Good Ol’ Chinese</h3>
                            <p className="blog-main-para">
                                Grab a fork (or chopsticks) and chow down on the chicken bits and yummy gravy after you leap off the Bumper Cars, feeling the exhaustion creeping in. The best Chinese food is the <a href="https://en.wikipedia.org/wiki/Kung_Pao_chicken">Kung Pao Chicken</a>, varying in spice levels (depending on your order) and dipped in rich sauces that make one keep ordering another platter when you are at MojMela Park.
                            </p>
                            <img src="../assets/image/culinary-fast-food.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h3 className="blog-sub-heading">Laden Plates of Fast Food</h3>
                            <p className="blog-main-para">Meaty patties between fat buns melted cheese dribbling down the front as jalapenos and honey-glazed onions poke out of the cheeseburger you ordered from the fast food vendor, the hunger cannot be more satisfied. Cheesy pizza, “tall and blonde” fries, and corndogs are among the many fast items you can buy from our eating area.</p>
                            <h3 className="blog-sub-heading">Mix It Up with Some Mexican</h3>
                            <p className="blog-main-para">We bring the prestige of Mexican food to you with enchiladas, tortillas, burritos, and taco wraps cooked in front of you. Hands that make your food with love at the best amusement park in Islamabad, you will be left licking your fingers after finishing. After testing your nerves on the climbing wall at MojMela, it makes sense to pump up the adrenalin with spicy, flavourful Mexican street food.</p>
                            <img src="../assets/image/culinary-pepsi.webp" alt="Mojmela Park In Islamabad" className='w-75 m-auto d-flex mb-4 rounded-3 blog-sub-img' />
                            <h3 className="blog-sub-heading">Beverages to Quench Your Thirst</h3>
                            <p className="blog-main-para">
                                All that screaming, screeching, and hollering can dry up your throat. Please don’t blame us; our Super Train and Zipline were to make your day! If you need something to quench your thirst, head to our eating section, where you will find rows and rows of all beverages, from cans of soft drinks to piping hot milk tea. You can slurp on cherry-flavored slushies or a good cup of coffee under the beating sun.
                            </p>
                            <h2 className="blog-sub-heading">Conclusion</h2>
                            <p className="blog-main-para">
                                MojMela is reasonably one of the best theme parks in Islamabad. Release all stress and take your soul on a drive; immerse yourself fully in the haven of pure joy — an amusement park with thrilling opportunities, good food, and unforgettable memories.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </main >
    )
}

export default Culinary
